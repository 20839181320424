// angular imports
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import PackageMetadata from '../../../../package.json';
import { Logger } from '../../utilities/logger/logger';
import {
  DocumentGeneration,
  FeatureSwitches,
  FreeTextSearchTypeahead,
  GeneralConfig,
  HubSpotIntegration,
  ProgressIndicator,
  SsoConfig,
  WeeksToMsFactor,
  CompanyUpdatePropertyRelationship,
  DebugSettings,
  ReferenceValueData,
  ThemeSettings,
  Localizer,
  LocalizedTextIds,
  ResponsiveConfig,
  StatusMetaData,
  MetaDataValue,
  Company,
  StatusMetaDataCollection,
  SearchBody,
  AggregateStats,
  getValueWithHeader,
  ValueWithHeader,
  getHeaderForProperty,
} from 'company-finder-common';
import { iframeResizer } from '@iframe-resizer/child';
import { Summary } from '../summary/summary';
declare global {
  export interface Window {
    iFrameResizer: iframeResizer.IFramePageOptions
    parentIFrame: iframeResizer.IFramePage
  }
}
@Injectable()
export class DeploymentContext {

  // private properties
  private _logger: Logger;
  private _baseUrl: string;
  private get _configFromServer(): GeneralConfig {
    return GeneralConfig.globalInstance;
  }
  private _localizer: Localizer;
  private _referenceValueData: ReferenceValueData;
  public comprehensiveSummary: Summary;
  public comprehensiveResults: AggregateStats;

  public get referenceValueData(): ReferenceValueData {
    return this._referenceValueData;
  }

  // constructor
  // ADJQ-1442 - The deployment context is initialized before the application
  // (see APP_INITILIZER code in app.module.ts for more detail). This is to ensure
  // that the context, including the configuration from the server, is set before
  // any of our other code runs. Please do not inject anything into this class
  // unless you are very sure what you are doing, as it can have unforseen
  // implications.
  public constructor() {
    this._logger = new Logger(this.constructor.name);
  }

  // public getters

  public get instanceId(): string {
    return this._configFromServer.instanceId;
  }

  public get themeSettings(): ThemeSettings {
    return this._configFromServer.themeSettings;
  }

  public get gtmId(): string {
    return this._configFromServer.gtmId;
  }

  public get appVersion(): string {
    return PackageMetadata.version;
  }

  public get appWidth(): number {
    let width = this._configFromServer.appWidth || 1024;
    if (!this.hosted()) {
      width = document.documentElement.clientWidth;
    }
    return width;
  }

  public get narrowScreenBreakpoint(): string {
    return this._configFromServer.themeSettings.vars.narrowScreenBreakpoint;
  }

  public get responsiveConfig(): ResponsiveConfig {
    return this._configFromServer.responsive;
  }

  public get baseSiteUrl(): string {
    if (this.hosted()) {
      return this._configFromServer.hostedUrl;
    } else {
      return location.origin + '/';
    }
  }

  public get standardHttpHeaders(): HttpHeaders {
    return new HttpHeaders({ 'Content-Type': 'application/json' });
  }

  /** Returns the full config tree.  This can be used for quick config settings,
   * but ideally we would continue to evolve the type specification of this.
   */
  public get rawConfig(): GeneralConfig {
    return this._configFromServer;
  }

  public get serverEnvironment(): string {
    return this._configFromServer.environment;
  }

  public get debug(): DebugSettings {
    return this._configFromServer.debug;
  }

  /**
   * Returns configuration settings for relationships between Company Update EditItems.
   */
  public get companyUpdatePropertyRelationships(): CompanyUpdatePropertyRelationship[] {
    return this._configFromServer.companyUpdatePropertyRelationships;
  }

  /**
   * Returns configuration settings for integration with HubSpot
   */
  public get hubSpotIntegrationConfig(): HubSpotIntegration {
    return this._configFromServer.hubSpotIntegration;
  }

  /**
   * Returns configuration setting for downloading Word & PDF documents.
   */
  public get documentGenerationConfig(): DocumentGeneration {
    return this._configFromServer.documentGeneration;
  }

  /**
   * Returns featureSwitches configuration.
   */
  public get featureSwitches(): FeatureSwitches {
    return this._configFromServer.featureSwitches;
  }

  /**
   * Returns freeTextSearchTypeahead configuration.
   */
  public get freeTextSearchTypeahead(): FreeTextSearchTypeahead {
    return this._configFromServer.freeTextSearchTypeahead;
  }

  /**
   * Returns configuration settings for integration with HubSpot
   */
  public get ssoConfig(): SsoConfig {
    return this._configFromServer.sso;
  }

  public get sectors(): string[] {
    return this._configFromServer.sectors;
  }

  public get sectorsAndAliases(): (string | string[])[] {
    return this._configFromServer.sectorsAndAliases;
  }

  public get statusMetadata(): StatusMetaData[] {
    return this._configFromServer.statusMetadata.metadata;
  }

  public get statusMetadataCollection(): StatusMetaDataCollection {
    return this._configFromServer.statusMetadata;
  }

  public getGroupOrderByDisplay(display: string): number {
    return this._configFromServer.statusMetadata.getGroupOrderByDisplay(
      display,
      this._localizer
    );
  }

  public getStatusMetadata(status: string): StatusMetaData {
    return this._configFromServer.statusMetadata.getMetadata(status);
  }

  public getStatusMetadataValue(status: string): MetaDataValue {
    return this._configFromServer.statusMetadata.getMetadataValue(status);
  }

  public getStatusDisplayName(status: string): string {
    return this._configFromServer.statusMetadata.getStatusDisplayName(status);
  }

  public getStatusTooltip(status: string): string {
    return this._configFromServer.statusMetadata.getStatusTooltip(status);
  }

  public getMetadataDisplayValue(status: string): string {
    return this._configFromServer.statusMetadata.getMetadataDisplayValue(
      status
    );
  }

  public getStatusDisplayNames(statuses: string[]): string[] {
    return this._configFromServer.statusMetadata.getDisplayNames(statuses);
  }

  public groupStatuses(statuses: string[]): {
    locationStatuses: string[];
    companyStatuses: string[];
  } {
    return this._configFromServer.statusMetadata.groupStatuses(statuses);
  }

  public get statusForTile(): string {
    return this._configFromServer.statusForTile;
  }

  public get statusForCompanyIcon(): string {
    return this._configFromServer.statusForCompanyIcon;
  }

  public get companyStatusIcon(): string {
    return this._configFromServer.companyStatusIcon;
  }

  public getCompaniesWithStatus(
    searchStatus: string,
    companies: Company[]
  ): Company[] {
    return this._configFromServer.statusMetadata.getCompaniesWithStatus(
      searchStatus,
      companies
    );
  }

  public get tileMetaData(): StatusMetaData {
    return this._configFromServer.statusMetadata.tileMetadata;
  }

  public get tileLabel(): string {
    return this._configFromServer.statusMetadata.tileMetadataDisplayValue;
  }

  public get tileTooltip(): string {
    return this._configFromServer.tooltipForTile;
  }

  public get iconTooltip(): string {
    return this._configFromServer.tooltipForCompanyIcon;
  }

  public getValueWithHeader(propertyName: string, company?: Company): ValueWithHeader {
    return getValueWithHeader(propertyName, this._localizer, company);
  }

  public getHeaderForProperty(propertyName): string {
    return getHeaderForProperty(propertyName, this._localizer);
  }

  public get lastViewedMyUpdatesLimit(): number {
    // This is so we don't indicate as new some outrageous number of events in the My Updates screen.
    // Our default, in the absence of a configuration value, is 2 weeks.
    return (
      this._configFromServer.behavior.lastViewedMyUpdatesLimit ||
      WeeksToMsFactor * 2
    );
  }

  public get lastViewedMyUpdatesTimestampDefault(): Date {
    const lastViewedBackstop = new Date(
      Date.now() - this.lastViewedMyUpdatesLimit
    );
    return new Date(lastViewedBackstop);
  }

  public get logoutSuccessToastTimeout(): number {
    return this._configFromServer.behavior.logoutSuccessToastTimeout || 5000;
  }

  public get juniverseConfigured(): boolean {
    return !!this._configFromServer.juniverseIntegration;
  }

  public get juniverseTokenEndpoint(): string {
    return this._configFromServer.juniverseIntegration.tokenEndpoint;
  }

  public get bypassJuniverse(): boolean {
    return this._configFromServer.juniverseIntegration.bypass;
  }

  public get autoSendBypass(): boolean {
    return this._configFromServer.juniverseIntegration.autoSendBypass;
  }

  public get tokenToSend(): string {
    return this._configFromServer.juniverseIntegration.tokenToSend;
  }

  public get companyOverrideToken(): string {
    return this._configFromServer.juniverseIntegration.companyOverrideToken;
  }

  public get internalOverrideToken(): string {
    return this._configFromServer.juniverseIntegration.internalOverrideToken;
  }

  public get internalSuperOverrideToken(): string {
    return this._configFromServer.juniverseIntegration
      .internalSuperOverrideToken;
  }

  public get partnerOverrideToken(): string {
    return this._configFromServer.juniverseIntegration.partnerOverrideToken;
  }
  public get bardaOverrideToken(): string {
    return this._configFromServer.juniverseIntegration.bardaOverrideToken;
  }

  public get invalidOverrideToken(): string {
    return this._configFromServer.juniverseIntegration.invalidOverrideToken;
  }

  public get juniverseLogin(): string {
    return this._configFromServer.juniverseIntegration.juniverseLogin;
  }

  /**
   * The amount of time, in milliseconds, to wait before displaying the progress indicator for long-running operations.
   */
  public get progressIndicator(): ProgressIndicator {
    return this._configFromServer && this._configFromServer.progressIndicator;
  }

  /**
   * The hour of the day at which email newsletters are scheduled to be sent.
   */
  public get sendNewsletterEmailsHourOfDay(): number {
    return this._configFromServer.email.sendNewsletterEmailsHourOfDay;
  }

  /**
   * The amount of time, in milliseconds, to wait before launching the tags picker modal,
   * giving the progress indicator a chance to render before the volumme of tags freezes the UI.
   */
  public get tagsPickerModalLaunchTimeout(): number {
    return this._configFromServer.behavior.tagsPickerModalLaunchTimeout || 100;
  }

  /**
   * The amount of time, in milliseconds, to wait before scrolling to the top of the my updates virtual scroll area.
   */
  public get virtualScrollToTopTimeout(): number {
    return this._configFromServer.behavior.virtualScrollToTopTimeout || 300;
  }

  // public methods
  public buildApiUrl(relativePath: string): string {
    return `${this._baseUrl}${relativePath}`;
  }

  /**
   * The URL of a website that will host this app (in an iframe)
   *
   * The browser is much pickier about postMessage origin matching than it is about
   * XHR reuqests. So, for example, where you are visiting Navigator from
   * https://jnjinnovation.com/JLABSNavigator the _aseUrl above will resolve
   * to the iframe URL of  https://jlabsportfolio.jnjinnovation.com/api/v1.
   * Since they are both jnjinnovation.com, they pass CORS. However, postMessage
   * will see that the subdomains are different and block the messaging. To
   * get around this, we let the API provide the proper hosted URL, in this case
   * https://jnjinnovation.com/JLABSNavigator, so that the application isn't
   * trying to change to a different URL. (Prior to this implementation we used angular configs
   * to generate different JS per environment at deploy time but this does not work in
   * a containerized implementation where the same build needs to deploy to all environments)
   */
  public get hostingSiteUrl(): string {
    return this._configFromServer.hostedUrl;
  }

  public hostingSiteOrigin(): string {
    return this._configFromServer.hostedUrl
      ? new URL(this._configFromServer.hostedUrl).origin
      : null;
  }

  public getSectorIndex(sector: string): number {
    return this.sectors.findIndex((s) => s === sector);
  }

  /**
   * This initialization is guaranteed to complete before the Angular App starts up
   * (due to how it is wired into app.module.ts)
   * Informed by https://www.intertech.com/Blog/angular-4-tutorial-run-code-during-app-initialization/
   */
  public async initialize(): Promise<void> {
    // initialize basic configuration
    this._baseUrl = `${window.location.origin}/api/v1`;

    // In most places in the application we use the HttpClient provided by
    // Angular in order to attach our error handling, auth headers, etc.
    // However, because the context is so low-level, injecting a HttpClient
    // means that our interceptors, which are attached to a HttpClient, would
    // throw a circular dependency at times due to HttpClient being a dependency.
    // To avoid this, we use the native Fetch call for the config calls

    // Retrieve some configuration settings from the server.
    try {
      const configResp = await fetch(`${this.buildApiUrl('/config')}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });

      const { config, localizationDict } = await configResp.json();

      GeneralConfig.globalInstance = config;
      this._localizer = new Localizer(localizationDict);

      // Several components use this data, some use it during early
      // lifecycle hooks to determine what to render. Having a long async
      // inside, for example, ngOnInit, can introduce race conditions as we saw in
      // ADJQ-1269. Because of that we want to take advantage of the fact that this path
      // runs before any components render and ensure the reference data is loaded prior
      // to any rendering. It also ensures the call is only made once, which is less chatty
      // though unlikely to be an issue for an application like Navigator. This is why
      // the data was moved into this method rather than have a dedicated service. It
      // could make sense to move this into the config call at this point, but there
      // does not seem to be a compelling reason to make that change at this point.
      const refResp = await fetch(`${this.buildApiUrl('/master-data')}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      });

      this._referenceValueData = await refResp.json();

      // Prior to this, we were making the async call to the search service to load the comprehensive data.
      // However, angular does not await ngOninit, even if it is async, so we were making 6+ calls to load
      // the page. Instead, load it once at app load and only make calls that have a prediticate and/or
      // filter to apply.
      const allCompanyStatsResp = await fetch(`${this.buildApiUrl('/search/company/aggregate')}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(new SearchBody())
      });
      this.comprehensiveResults = await allCompanyStatsResp.json();
      this.comprehensiveSummary = new Summary(this.comprehensiveResults);

      // Inject site/instance-specific styling
      const vars = this.themeSettings.vars;
      if (vars) {
        const varsList = Object.keys(vars)
          .map((k) => `--${k}: ${vars[k]};`)
          .join('\n');
        const style = `:root { ${varsList}}`;
        const styleElement = document.createElement('style');
        styleElement.appendChild(document.createTextNode(style));
        document.head.appendChild(styleElement);
      }

      this._logger.log(
        `DeploymentContext - online for Portfolio Tool v${this.appVersion}`
      );
    } catch (error) {
      this._logger.error(
        `DeploymentContext: error while retrieving configuration from server - ${error}`
      );
    }
  }

  // listen for message indicating click from parent frame
  public listenForClick(): void {
    window.addEventListener(
      'message',
      function (event) {
        if (event.data !== undefined && event.data.for === 'click') {
          window.document.head.click();
        }
      },
      false
    );
  }

  public get AcsUrl(): string {
    return this.hosted() ? `${this.hostingSiteUrl}/acs` : `/acs`;
  }

  public hosted(): boolean {
    // Rather than check URL's, assume that the presense
    // of an initialized iframe-resizer means we are hosted
    // in an iframe. Most of our iframe logic relies on the
    // iframe object existing anyway, so if it is null we 
    // will have plenty of issues.
    return !!this.iframe;
  }

  public get subsectorsIndependentOnDropdown(): boolean {
    return (
      this._configFromServer.behavior?.subsectorsIndependentOnDropdown ?? true
    );
  }

  public get subsectorsPerColumn(): number {
    return this._configFromServer.behavior?.subsectorsPerColumn ?? 8;
  }

  public get iframe(): iframeResizer.IFramePage {
    return window.parentIFrame;
  }

  private _footer: HTMLElement;
  public initFooter(footer: HTMLElement) {
    this._footer = footer;
  }

  public get footer(): HTMLElement {
    return this._footer;
  }

  public get footerHeight(): number {
    return this.footer?.offsetHeight ?? 0;
  }

  /** If the app is configured to be framed, this ensures the URLs are handled properly */
  public ensureHostedNavigation(url: string): void {
    // Since there won't be a parentIFrame unless we are hosted, the null check is sufficient 
    // NOTE: The logic below needs to be coordinated with src\assets\IntegrationWithDrupal\iframedPortfolioTool.js
    this.sendMessageToIframe(
      { for: 'iframenav', operation: 'changeroute', route: url }
    );
  }

  public sendMessageToIframe(message: unknown): void {
    // We do not supply a target origin here to enable the single
    // deployed dev environment (and possibly others down the road)
    // to be able to be tested inside multiple iframed parents,
    // so that the dev Singapore and Korea sites can use the single
    // Universal Vanilla Dev instance for testing.
    //
    // It is safe to send a message to the parent without a 
    // target here because:
    //
    // 1. We do not send any sensitive data, only URL,
    //    title, and meta changes, and iframe resize events.
    // 2. Nothing prevents a user from modifying this prior to invoking it
    // 3. Our other CORS protection schemes cover this
    this.iframe?.sendMessage(message);
  }


  /** When hosted in an iframe, the scroll position of that iframe is independent of navigation
   * within the Angular app.  This ensures it gets scrolled to the top.
   */
  public ensureScrolledToTop(): void {
    this.scrollVertical(0);
    this.scrollToTopOnNextUpdate = false;
  }

  public scrollVertical(y: number) {
    setTimeout(() => (this.iframe ?? self).scrollTo(0, y), this.virtualScrollToTopTimeout);
  }

  // Set this to indicate you want the UI to scroll to top at some point soon,
  // but not right now.  The app component will check this each time it handles
  // an update with change detection and will scroll if needed.
  public scrollToTopOnNextUpdate = false;

  public setSsoHref(url: string): void {
    this.setUrl(url, 'sso');
  }

  public setJuniverseHref(): void {
    const url = this.juniverseLogin;
    this.setUrl(url, 'sso');
  }

  private setUrl(url: string, reason: string): void {
    if (this.hosted()) {
      this.sendMessageToIframe(
        { for: reason, operation: 'changehref', url: url }
      );
    } else {
      window.location.href = url;
    }
  }

  public get LocalizedTextIds(): typeof LocalizedTextIds {
    return LocalizedTextIds;
  }

  public Loc(
    key: string,
    ...replacements: (string | number | boolean)[]
  ): string {
    return this._localizer?.Localize(key, null, ...replacements);
  }

  public LocPluralize(
    key: string,
    pluralNumber: number,
    ...replacements: (string | number | boolean)[]
  ): string {
    return this._localizer?.Localize(key, pluralNumber, ...replacements);
  }

  public LocWithPrefix(
    key: string,
    prefix: string,
    tryWithNoPrefix: boolean = false
  ): string {
    return this._localizer?.LocalizeWithPrefix(key, prefix, tryWithNoPrefix);
  }
}
