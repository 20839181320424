import { Component, Input } from '@angular/core';
import { ComponentBase } from '../../../../_common/components/_component.base';
import { Company, ValueWithHeader } from 'company-finder-common';

@Component({
  selector: 'property-detail-list',
  templateUrl: './property-detail-list.component.html',
  styleUrls: ['./property-detail-list.component.scss'],
})
export class PropertyDetailListComponent extends ComponentBase {
  @Input()
  public company: Company;

  @Input()
  public properties: string[] = [];

  private _tabDetails: ValueWithHeader[] = null;

  public get tabDetails(): ValueWithHeader[] {
    if (!this._tabDetails) {
      this._tabDetails = this.properties?.map(
        (property) => this.getValueWithHeader(property, this.company));
    }

    return this._tabDetails;
  }
}

