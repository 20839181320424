import { Component } from '@angular/core';
import { UpdateComponentBaseWithEditItems } from '../../UpdateComponentBaseWithEditItems';
import { FundingCurrency } from 'company-finder-common';
import { EditItemChoice } from '../../company-update.interface';

@Component({
  selector: 'blue-knight-milestones-update',
  templateUrl: './blue-knight-milestones-update.component.html',
  styleUrls: ['./blue-knight-milestones-update.component.scss'],
})
export class BlueKnightMilestonesUpdateComponent
  extends UpdateComponentBaseWithEditItems
{
  public get currencyTypes(): FundingCurrency[] {
    return this._deploymentContext.referenceValueData.fundingCurrencyList.map(
      (item) => item
    );
  }

  public get teamSizeOptions(): EditItemChoice[]  {
    return this._deploymentContext.referenceValueData.teamSizeOptions;
  }

  public get blueKnightFundingRounds(): EditItemChoice[]  {
    return this._deploymentContext.referenceValueData.blueKnightFundingRounds;
  }

  public get blueKnightPharmaStages(): EditItemChoice[]  {
    return this._deploymentContext.referenceValueData.blueKnightPharmaStage;
  }

  public get blueKnightMedTechStages(): EditItemChoice[]  {
    return this._deploymentContext.referenceValueData.blueKnightMedTechStage;
  }

  public get regulatoryStages(): EditItemChoice[]  {
    return this._deploymentContext.referenceValueData.regulatoryStages;
  }

  public get showFundingdetails(): boolean {
    return this.company.fundingStage === 'Other';
  }
}
