import {
  ContentChildren,
  Directive,
  Input,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UpdateComponentBase } from './UpdateComponentBase';
import { EditItemComponent } from './components/edit-item/edit-item.component';
import {
  Deal,
  Funding,
  LocalizedTextIds,
} from 'company-finder-common';

@Directive()
export abstract class UpdateComponentBaseWithEditItems extends UpdateComponentBase {
  // The edit components are subscribed to in the main update component, both for
  // any it has and for each tab component. This is a separate base so that
  // the EditItemComponent itself can have all the company items, the current mode, etc
  @ViewChildren(EditItemComponent)
  public editItemComponents: QueryList<EditItemComponent>;
  @ContentChildren(EditItemComponent)
  public editItemContent: QueryList<EditItemComponent>;

  public get editItemArray(): EditItemComponent[] {
    return this.editItemComponents?.toArray() || [];
  }

  @Input()
  public companyEditForm: FormGroup;

  // If an *ngFor loop does not have a unique identifier for each object in the array,
  // it may lose the binding between the DOM element and the object during changes
  // to the DOM. To avoid this, we provide a custom TrackBy function for the objects
  // to ensure a stable unique identifier for each one.
  public dealTrackBy(_index: number, deal: Deal): string {
    return deal.dealId;
  }

  public fundingTrackBy(_index: number, funding: Funding): string {
    return funding.fundingId;
  }
 
  public parseMultipleChoices(
    separatedValueString: string,
    separator = ';'
  ): string[] {
    if (!separatedValueString) {
      return [];
    }
    const separatedValues = separatedValueString.split(separator);
    const unprefixedValues = separatedValues.map((value) => {
      const key = ' - ';
      const index = value.indexOf(key);
      if (index >= 0) {
        return value.slice(index + key.length);
      } else {
        return value;
      }
    });
    // Make sure they are sorted, so the diffs can reliably match sets with the same options.
    return unprefixedValues.sort();
  }

  public get yesNoOptionsList(): { label: string; value: string }[] {
    return [
      { label: '', value: '' },
      { label: this.Loc(LocalizedTextIds.Yes), value: 'Yes' },
      { label: this.Loc(LocalizedTextIds.No), value: 'No' },
    ];
  }
}
