import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
 
import { TrimDirective } from './trim.directive';
 
@NgModule({
  declarations: [TrimDirective],
  exports: [TrimDirective],
  imports: [CommonModule]
})
export class TrimModule { }