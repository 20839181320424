import { TagWithCount, PublicOverrides, Localizer } from 'company-finder-common';
import { RoleGroups, UserRoles } from '../model';
import { Company } from '../model/company';
import { Opportunity } from '../model/opportunity';
import { ValueWithHeader, ValueWithHeaderId } from '../model/ValueWithHeaderId';
import { LocalizedTextIds } from '../config/localizedTextIds';
import { nameof } from '../utility/string-util';

export enum CompanyResolutionType {
    Details = 'details',
    Update = 'update',
}

export function isNewCompany(company: Company): boolean {
    const threeMonthsAgo: Date = new Date();
    threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
    // NOTE: Javascript Dates are serialized & deserialized as strings, so rehydrate the commencement date then compare.
    return new Date(company.commencementDate) > threeMonthsAgo;
}

/**
 * Removes fields from the company object that should not be shown
 * to the current user, based on their role.
 *
 * N.B. Since there is no way for an unauthenticated user to request
 * a single company, this will *NOT* remove fields that are visible only
 * to authenticated users for an unauthenticated user.
 * @param company The company to sanitize
 */
export function stripSensitiveFields(
    company: Company,
    currentUserRole: UserRoles,
    publicFields: PublicOverrides // Even though this is in the config, adding it will create a circular reference here
): void {
    if (!company) {
        return;
    }
    // We only ever use this on the backend, no reason to return
    delete company.coOwners;

    if (!RoleGroups.InternalSuperUsers.containsRole(currentUserRole)) {
        // Remove JJI-specific fields
        //  Note that we assume all Site/Region Heads and Opportunity Co-Owners will be JJI,
        //  otherwise stripping of some data and/or authorization restrictions could present problems with Company Update.
        delete company.opportunities;
        delete company.progressCreatedDate;
        delete company.progressUpdate;
        delete company.progressSubject;
        delete company.limitedDealOpportunities;

        company.deals = company.deals?.filter((deal) => !deal.isConfidential);
        company.funding = company.funding?.filter(
            (funding) => !funding.isConfidential
        );
    }

    // Remove J&J only fields
    if (!RoleGroups.InternalUsers.containsRole(currentUserRole)) {
        delete company.siteHead;

        if (!publicFields.companyContact) {
            delete company.companyContact;
        }
        delete company.priority;
        delete company.keyMgmtAndAdvBm;
        delete company.keyDifferentiation;

        if (!publicFields.investmentSummary) {
            delete company.highestLevelOfFundingSecured;
            delete company.totalSecuredAndContingentAmount;
        }

        if (!publicFields.deals) {
            delete company.deals;
        }

        if (!publicFields.funding) {
            delete company.funding;
        }

        delete company.jpalContact;
        delete company.womenLed;
        delete company.womenLedOrgLeadership;
        delete company.womenLedBoardOfDirectorsOrEquiv;
        delete company.countryForDeiReporting;
        delete company.firstTimeEntrepreneur;
        delete company.topTierTeam;

        if (!publicFields.currentRdStage) {
            delete company.currentRdStage;
        }
    }

    if (!RoleGroups.InternalOrBardaUsers.containsRole(currentUserRole)) {
        removeBlueKnightData(company);
    }
}

export function removeBlueKnightData(company: Company): void {
    // Blue Knight fields are listed, so take advantage of that to strip them all out
    for (const key in company) {
        if (Company.isBlueKnightProperty(key)) {
            delete company[key];
        }
    }
}

export function hasDealsWithJnJ(company: Company, includeLimitedOpps = false): boolean {
    return (
        contractExecutedOpportunities(company).length > 0 ||
        // Brittany mentioned potentially modeling all "deals" as opportunity:'contract executed'
        //  and eliminating the need to include based on isQfcWinner.  Until that time, we need to look at this too.
        company.isQfcWinner ||
        (includeLimitedOpps && company.limitedDealOpportunities?.length > 0)
    );
}

export function contractExecutedOpportunities(company: Company): Opportunity[] {
    return company.opportunities?.filter(
        (opp) => opp.stage === 'Contract Executed'
    ) ?? new Array<Opportunity>();
}

export function stringIsJforceId(input: string) {
    // A J&J opportunity ID is a salesforce ID. Salesforce IDs
    // are always 18 digit identifiers and the first three characters
    // are a code representing the object type. Checking the data lake
    // verified that this is '006' across all environments for opportunity IDs
    return input.length === 18 && input.startsWith('006');
}

/**
 * Returns all tags in a decreasing frequency-based sort.
 */
export function orderByTagCount(
    company: Company,
    tagCounts: Array<TagWithCount>
): string[] {
    return company?.tags
        .reduce((result, tag) => {
            const item = tagCounts?.find((tagCount) => tagCount.tag === tag);
            result.push({ tag: tag, count: item ? item.count : 0 });
            return result;
        }, new Array<TagWithCount>())
        .sort((a, b) => b.count - a.count)
        .map((item) => item.tag) ?? [];
}

export function urlIdentifierForCompany(companyName: string): string {
    companyName = companyName.replace(/_/g, '__');
    companyName = companyName.replace(/ /g, '_')
    return encodeURIComponent(companyName);
}

export function urlIdentifierToCompanyName(urlValue: string): string {
    let companyName = decodeURIComponent(urlValue);
    companyName = companyName.replace(/_/g, ' ');
    companyName = companyName.replace(/  /g, '_');
    return companyName;
}

export function getValueWithHeader(propertyName: string, localizer: Localizer, company?: Company,): ValueWithHeader {
    const valWithHeader = {
        value: company ? company[propertyName] : undefined,
        header: getHeaderForProperty(propertyName, localizer)
    };
    return valWithHeader;
}

export function getHeaderForProperty(propertyName: string, localizer: Localizer): string {
    return localizer.Localize(getHeaderIdForProperty(propertyName));
}

export function getHeaderIdForProperty(propertyName: string): LocalizedTextIds {
    // The company contact is a bit of an odd field, treated as a top-level
    // in company update only to be set as a read-only field.
    // This means it won't work as a nameOf<'contactName'> since it's not a property
    // on the actual company object.
    // It seemed better to isolate the "one-off" code here in a central place, 
    // rather than add custom handling into the edit-item.component.
    if (propertyName === 'contactName') {
        return LocalizedTextIds.CompanyContact;
    } else if (propertyName === 'companyContactTitle') {
        return LocalizedTextIds.ContactTitle;
    }

    switch (propertyName) {

        case nameof<Company>('majorMilestones'):
            return LocalizedTextIds.MajorMilestones;
        case nameof<Company>('currentPharmaStage'):
            return LocalizedTextIds.CurrentStagePharma;
        case nameof<Company>('currentMedTechStage'):
            return LocalizedTextIds.CurrentStageMedTech;
        case nameof<Company>('stageDetails'):
            return LocalizedTextIds.AdditionalContext;
        case nameof<Company>('regulatoryStatus'):
            return LocalizedTextIds.RegStatus;
        case nameof<Company>('anticipatedComercialProductYr'):
            return LocalizedTextIds.AnticipatedComercialProductYr;
        case nameof<Company>('nonLeadRegStatus'):
            return LocalizedTextIds.NonLeadRegStatus;
        case nameof<Company>('currencyBlueKnight'):
            return LocalizedTextIds.Currency;
        case nameof<Company>('fundingStage'):
            return LocalizedTextIds.FundingStage;
        case nameof<Company>('fundingStageDetails'):
            return LocalizedTextIds.FundingStageDetails;
        case nameof<Company>('nonDilutiveFunding'):
            return LocalizedTextIds.NonDilutiveFunding;
        case nameof<Company>('dilutiveFunding'):
            return LocalizedTextIds.DilutiveFunding;
        case nameof<Company>('addtlIPDetails'):
            return LocalizedTextIds.AdditionalIPDetails;
        case nameof<Company>('filedIP'):
            return LocalizedTextIds.FiledIP;
        case nameof<Company>('securedIP'):
            return LocalizedTextIds.SecuredIP;
        case nameof<Company>('compositionOfMatter'):
            return LocalizedTextIds.CompositionOfMatter;
        case nameof<Company>('overallTrl'):
            return LocalizedTextIds.OverallTrl;
        case nameof<Company>('alignedTrl'):
            return LocalizedTextIds.AlignedTrl;
        case nameof<Company>('commercialPartnerships'):
            return LocalizedTextIds.BlueKnightCommercialPartnerships;
        case nameof<Company>('rAndDPartnerships'):
            return LocalizedTextIds.BlueKnightRDPartnerships;
        case nameof<Company>('otherPartnerships'):
            return LocalizedTextIds.BlueKnightOtherPartnerships;
        case nameof<Company>('currentRdStage'):
            return LocalizedTextIds.CompanyDetailsLeadProductStage;
        case nameof<Company>('tags'):
            return LocalizedTextIds.Tags;
        case nameof<Company>('description'):
            return LocalizedTextIds.Description;
        case nameof<Company>('website'):
            return LocalizedTextIds.Website;
        case nameof<Company>('logoBase64'):
            return LocalizedTextIds.Logo;
        case nameof<Company>('name'):
            return LocalizedTextIds.CompanyName;
        case nameof<Company>('womenLedBoardOfDirectorsOrEquiv'):
            return LocalizedTextIds.JnjInformationWomenLedBoard;
        case nameof<Company>('womenLedOrgLeadership'):
            return LocalizedTextIds.JnjInformationWomenLedOrg;
        case nameof<Company>('boardAdvisorDiversity'):
            return LocalizedTextIds.JnjInformationBoardAdvisor;
        case nameof<Company>('leadershipDiversity'):
            return LocalizedTextIds.JnjInformationLeadershipDiversity;
        case nameof<Company>('countryForDeiReporting'):
            return LocalizedTextIds.Country;
        case nameof<Company>('firstTimeEntrepreneur'):
            return LocalizedTextIds.JnjInformationFirstTimeEntrepreneur;
        case nameof<Company>('keyDifferentiation'):
            return LocalizedTextIds.CompanyDetailsKeyDifferentiation;
        case nameof<Company>('keyMgmtAndAdvBm'):
            return LocalizedTextIds.CompanyDetailsKeyManagement;
        case nameof<Company>('isQfcWinner'):
            return LocalizedTextIds.QFCAwardee;
        case nameof<Company>('isBlueKnight'):
            return LocalizedTextIds.BlueKnight;
        case nameof<Company>('problem'):
            return LocalizedTextIds.Problem;
        case nameof<Company>('solution'):
            return LocalizedTextIds.Solution;
        case nameof<Company>('companyObjective'):
            return LocalizedTextIds.BlueKnightInformationAlignment;
        case nameof<Company>('alignedGoal'):
            return LocalizedTextIds.BlueKnightGoal;
        case nameof<Company>('approachUsecase'):
            return LocalizedTextIds.CompanyDetailsApproach;
        case nameof<Company>('entryExitStrategy'):
            return LocalizedTextIds.CompanyDetailsAnticipated;
        case nameof<Company>('challenges'):
            return LocalizedTextIds.BlueKnightInformationChallenges;
        case nameof<Company>('priorities'):
            return LocalizedTextIds.BlueKnightInformationPriorities;
        case nameof<Company>('mentorship'):
            return LocalizedTextIds.CompanyDetailsMentorship;
        case nameof<Company>('engagement'):
            return LocalizedTextIds.CompanyDetailsEngagement;
        case nameof<Company>('conferencesAndEvents'):
            return LocalizedTextIds.ConferencesAndEvents;
        case nameof<Company>('compositionAndGrowth'):
            return LocalizedTextIds.BlueKnightInformationCompositionAndGrowthHeader;
        case nameof<Company>('currentTeamSizeRange'):
            return LocalizedTextIds.BlueKnightInformationCurrentSize;
        default:
            return null;
    }
}