import { ICompanyUpdate } from './company-update.interface';
import { IDealUpdate } from './deal-update.interface';
import { IFundingUpdate } from './funding-update.interface';
import { IEntityBase } from './entity-base.interface';

export enum ProcessedStatus {
    NotStarted = 'not started',
    Retry1 = 'retry1',
    Retry2 = 'retry2',
    Failed = 'failed',
    Success = 'success',
}

export enum UpdateStatus {
    /** This field/update has been reviewed and approved. 
     * Note that an updated is considered approved if any individual field was approved,  
     * regardless of the number of declined fields. */
    Approved = 'approved',
    /** This field/update has been reviewed and declined. */
    Declined = 'declined',
    /** This field/update has been submitted but not reviewed. */
    Pending = 'pending',
    /** The field/update has not been submitted but was stored in the db for the user to complete at a later date */
    Saved = 'saved',
}

export interface IUpdateField extends IEntityBase {
    emailAddress: string;
    name: string;
    nav_table_name: string;
    backend_destination: string;
    newValue: string;
    oldValue: string;
    processed: ProcessedStatus;
    status: UpdateStatus;
    companyUpdate: ICompanyUpdate;
    dealUpdate: IDealUpdate;
    fundingUpdate: IFundingUpdate;
    processedDate: Date;
    processedComments: string;
}
