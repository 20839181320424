import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  Provider,
  NgModule,
  ErrorHandler,
} from '@angular/core';

import { ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { UiScrollModule } from 'ngx-ui-scroll';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// utilities & services
import { ApplicationContext } from '../_common/utilities/application-context/application-context';
import { AuthnInterceptor } from '../_common/interceptors/authn.interceptor';
import { HttpErrorInterceptor } from '../_common/interceptors/httperror.interceptor';
import { AuthnService } from '../_common/services/authn/authn.service';
import { TokenService } from '../_common/services/token/token.service';
import { BreadcrumbsService } from '../_common/services/breadcrumbs/breadcrumbs.service';
import { CanActivateUpdateRouteGuard } from '../_common/guards/can-activate-update-route-guard';
import { RoleCanActivateRouteGuard } from '../_common/guards/role-can-activate-route-guard';
import { CanActivateMyUpdatesRouteGuard } from '../_common/guards/can-activate-my-updates-route-guard';
import { RemoveOldInternalRouteGuard } from '../_common/guards/remove-old-internal-route-guard';
import { PendingChangesGuard } from '../_common/guards/pending-changes-guard';
import { CompanyService } from '../_common/services/company/company.service';
import { DeploymentContext } from '../_common/utilities/deployment-context/deployment-context';
import { EventService } from '../_common/services/event/event.service';
import { HealthCheckService } from '../_common/services/health-check/health-check.service';
import { LogService } from '../_common/services/log/log.service';
import { JnjErrorHandler } from '../_common/utilities/jnj-error-handler/jnj-error-handler';
import { LargeNumberCurrencyPipe } from '../_common/pipes/large-number-currency.pipe';
import { Nl2BrPipe } from '../_common/pipes/nl2br.pipe';
import { ProgressIndicatorService } from '../_common/services/progress-indicator/progress-indicator.service';
import { SearchService } from '../_common/services/search/search.service';
import { WebAnalyticsService } from '../_common/services/web-analytics/web.analytics';
import { TitleAndMetadata } from '../_common/utilities/title-and-metadata/title-and-metadata';

// top-level components
import { AcsComponent } from './sso/acs.component';
import { ByTheNumbersComponent } from './by-the-numbers/by-the-numbers.component';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { InvestmentSummaryComponent } from './company-details/components/investment-summary/investment-summary.component';
import { DealsTableComponent } from './company-details/components/deals-table/deals-table.component';
import { DetailSectionComponent } from './company-details/components/detail-section/detail-section.component';
import { FundingTableComponent } from './company-details/components/funding-table/funding-table.component';

import { DownloadComponent } from './company-details/components/download/download.component';
import { CompanyUpdateComponent } from './company-update/company-update.component';
import { FilterComponent } from './filter/filter.component';
import { StatusSelectorComponent } from './filter/components/status-selector/status-selector.component';
import { SectorSelectorComponent } from '../_common/components/selector/sector/sector-selector.component';
import { SimpleSelectorComponent } from '../_common/components/selector/simple/simple-selector.component';
import { HealthCheckComponent } from './health-check/health-check.component';
import { LoginFooterComponent } from './login-footer/login-footer.component';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { MyUpdatesComponent } from './my-updates/my-updates.component';
import { OverviewComponent } from './overview/overview.component';
import { PreferencesComponent } from './user/preferences.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SsoComponent } from './sso/sso.component';
import { UpdateComponent } from './update-generic/update.component';

// shared components
import { BreadcrumbsComponent } from '../_common/components/breadcrumbs/breadcrumbs.component';
import { CompanyLocationStatusComponent } from '../_common/components/company-location-status/company-location-status.component';
import { StatusListComponent } from '../_common/components/status-list/status-list.component';
import { CompanyLogoComponent } from '../_common/components/company-logo/company-logo.component';
import { CompanyTagsComponent } from '../_common/components/company-tags/company-tags.component';
import { DisclaimerComponent } from './main/disclaimer/disclaimer.component';
// eslint-disable-next-line max-len
import { JnjLogoutSuccessToastComponent } from '../_common/components/log-in-out-banner/components/jnj-logout-success-toast/jnj-logout-success-toast.component';
import { JnjToastComponent } from '../_common/components/jnj-toast/jnj-toast.component';
import { LogInOutBannerComponent } from '../_common/components/log-in-out-banner/log-in-out-banner.component';
import { ProgressIndicatorComponent } from '../_common/components/progress-indicator/progress-indicator.component';
import { ShowMoreLessComponent } from '../_common/components/show-more-less/show-more-less.component';
import { SimpleTabUIComponent } from '../_common/components/simple-tab-ui/simple-tab-ui.component';
import { SiteHeaderComponent } from '../_common/components/site-header/site-header.component';
import { PickerModalComponent } from '../_common/components/picker-modal/picker-modal.component';
import { TagsPickerModalComponent } from '../_common/components/picker-modal/tags-picker-modal.component';
import { CreateCustomTagComponent } from '../_common/components/picker-modal/create-custom-tag.component';
import { MutableCollectionComponent } from '../_common/components/mutable-collection/mutable-collection.component';
import { ActionModalComponent } from '../_common/components/action-modal/action-modal.component';
import { NotificationsModalComponent } from './user/components/notifications-modal.component';
import { DropdownMenuComponent } from '../_common/components/dropdown-menu/dropdown-menu.component';
import { ModalOverlayComponent } from '../_common/components/modal-overlay/modal-overlay.component';
import { StayInTheLoopComponent } from '../_common/components/stay-in-the-loop/stay-in-the-loop.component';
import { ActionButtonComponent } from '../_common/components/action-button/action-button.component';
import { ViewportContainerComponent } from '../_common/components/viewport-container/viewport-container.component';
import { SelectInputComponent } from '../_common/components/inputs/select-input/select-input.component';
import { ToggleInputComponent } from '../_common/components/inputs/toggle-input/toggle-input.component';
import { StickyOnScrollComponent } from '../_common/components/sticky-on-scroll/sticky-on-scroll.component';
import { LocalizedTextSnippetComponent } from '../_common/components/localized-text-snippet/localized-text-snippet.component';
import { CompanyStatusIconComponent } from '../_common/components/company-status-icon/company-status-icon.component';

// by-the-numbers components
import { AnnulusComponent } from './by-the-numbers/components/community-and-diversity/components/annulus/annulus.component';
import { CommunityAndDiversityComponent } from './by-the-numbers/components/community-and-diversity/community-and-diversity.component';
import { StrategicCollaborationComponent } from './by-the-numbers/components/strategic-collaboration/strategic-collaboration.component';

// overview components
import { DealsComponent } from './overview/components/deals/deals.component';
import { ExploreComponent } from './overview/components/explore/explore.component';
import { LocationIndicatorComponent } from './overview/components/explore/components/location-indicator/location-indicator.component';
import { GeoVisualizerComponent } from './overview/components/explore/components/geo-visualizer/geo-visualizer.component';
import { NewCompaniesComponent } from './overview/components/new-companies/new-companies.component';
import { SectorDetailsComponent } from './overview/components/deals/components/sector-details/sector-details.component';
import { SectorMapComponent } from './overview/components/explore/components/sector-map/sector-map.component';
import { StageComponent } from './overview/components/stage/stage.component';
import { SummaryComponent } from './overview/components/summary/summary.component';
import { TagCloudComponent } from './overview/components/explore/components/tag-cloud/tag-cloud.component';

// search bar components
import { NavigationPanelComponent } from './search-bar/components/navigation-panel/navigation-panel.component';

// search result components
import { CompanySummaryComponent } from './search-results/components/company-summary/company-summary.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { SortControlComponent } from './search-results/components/sort-control/sort-control.component';
import { LargeDownloadModalComponent } from './search-results/components/large-download-modal/large-download-modal.component';

// company-details components
import { ContactComponent } from './company-details/components/contact/contact.component';
import { BlueKnightInformationComponent } from './company-details/components/blue-knight-information/blue-knight-information.component';
import { PropertyDetailListComponent } from './company-details/components/property-detail-list/property-detail-list.component';
import { BlueKnightMilestonesComponent } from './company-details/components/blue-knight-milestones/blue-knight-milestones.component';
import { JnjInformationComponent } from './company-details/components/jnj-information/jnj-information.component';
import { PublicInformationComponent } from './company-details/components/public-information/public-information.component';
import { NgxPaginationModule } from 'ngx-pagination';

// my-updates components
import { PillComponent } from './my-updates/components/pill/pill.component';
import { UpdateSummaryComponent } from './my-updates/components/update-summary/update-summary.component';

// self-update components
import { ApproveDeclineComponent } from './company-update/components/approve-decline/approve-decline.component';
import { BlueKnightInformationUpdateComponent } from './company-update/components/blue-knight-information-update/blue-knight-information-update.component';
import { BlueKnightMilestonesUpdateComponent } from './company-update/components/blue-knight-milestones-update/blue-knight-milestones-update.component';
// eslint-disable-next-line max-len
import { CompanyUpdateConfirmationComponent } from './company-update/components/company-update-confirmation/company-update-confirmation.component';
import { CompanyUpdateModalComponent } from './company-update/components/company-update-modal/company-update-modal.component';
import { DealModalComponent } from './company-update/components/deal-modal/deal-modal.component';
import { EditItemComponent } from './company-update/components/edit-item/edit-item.component';
import { EditsSummaryComponent } from './company-update/components/edits-summary/edits-summary.component';
import { FundingModalComponent } from './company-update/components/funding-modal/funding-modal.component';
import { JnJInformationUpdateComponent } from './company-update/components/jnj-information-update/jnj-information-update.component';
import { NotificationsComponent } from './company-update/components/notifications/notifications.component';
import { PublicInformationUpdateComponent } from './company-update/components/public-information-update/public-information-update.component';
// eslint-disable-next-line max-len
import { ReviewDealFundingComponent } from './company-update/components/review-deal-funding/review-deal-funding.component';
import { ReviewModalComponent } from './main/review-modal/review-modal.component';
import { UpdateHeaderComponent } from './company-update/components/header/update-header.component';
import { UserService } from '../_common/services/user/user.service';

// user preferences components
import { CompaniesPickerModalComponent } from './user/components/companies-picker-modal.component';
import { SectorsPickerModalComponent } from './user/components/sectors-picker-modal.component';
import { LocationsPickerModalComponent } from './user/components/locations-picker-modal.component';
import { SavedSearchModalComponent } from './user/components/saved-search-modal/saved-search-modal.component';
import { SavedSearchSummaryComponent } from './user/components/saved-search/saved-search-summary.component';
import { PreferencesSectionComponent } from './user/components/preferences-section/preferences-section.component';
// eslint-disable-next-line max-len
import { SavedSearchCriteriaBaseComponent } from './user/components/saved-search-modal/saved-search-criteria-base/saved-search-criteria-base.component';
import { SavedSearchCriteriaComponent } from './user/components/saved-search-modal/saved-search-criteria/saved-search-criteria.component';
// eslint-disable-next-line max-len
import { SavedSearchSectorSelectorComponent } from './user/components/saved-search-modal/saved-search-sector-selector/saved-search-sector-selector.component';
// eslint-disable-next-line max-len
import { SavedSearchSimpleSelectorComponent } from './user/components/saved-search-modal/saved-search-simple-selector/saved-search-simple-selector.component';
import { SavedSearchBaseComponent } from './user/components/saved-search/saved-search-base.component';
import { SwiperDirective } from '../_common/directives/swiper';
import { TouchTooltipDirective } from '../_common/directives/touchTooltip';
import { NewsComponent } from './company-details/components/news/news/news.component';
import { NgxD3Service } from '../_common/services/d3';
import { CompanyUpdateService } from './company-update/services/company-update.service';
import { ReviewEditsService } from './company-update/services/review-edits.service';
import { TrimModule } from '../_common/utilities/trim/trim.module';

const providers: Provider[] = [
  {
    provide: APP_INITIALIZER,
    useFactory: (ctx: DeploymentContext) => () => ctx.initialize(),
    deps: [DeploymentContext],
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthnInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true,
  },
  {
    provide: ErrorHandler,
    useClass: JnjErrorHandler,
  },
  ApplicationContext,
  AuthnService,
  BreadcrumbsService,
  CanActivateUpdateRouteGuard,
  CanActivateMyUpdatesRouteGuard,
  RemoveOldInternalRouteGuard,
  RoleCanActivateRouteGuard,
  PendingChangesGuard,
  CompanyService,
  CompanyUpdateService,
  CurrencyPipe,
  DatePipe,
  DeploymentContext,
  NgxD3Service,
  EventService,
  HealthCheckService,
  LogService,
  ProgressIndicatorService,
  ReviewEditsService,
  SearchService,
  TitleAndMetadata,
  TokenService,
  UserService,
  WebAnalyticsService,
];

@NgModule({
  declarations: [
    AcsComponent,
    AppComponent,
    ApproveDeclineComponent,
    AnnulusComponent,
    BlueKnightInformationComponent,
    PropertyDetailListComponent,
    BlueKnightInformationUpdateComponent,
    BlueKnightMilestonesComponent,
    BlueKnightMilestonesUpdateComponent,
    BreadcrumbsComponent,
    ByTheNumbersComponent,
    CommunityAndDiversityComponent,
    CompanyDetailsComponent,
    InvestmentSummaryComponent,
    DealsTableComponent,
    FundingTableComponent,
    CompanyUpdateComponent,
    CompanyLocationStatusComponent,
    CompanyLogoComponent,
    CompaniesPickerModalComponent,
    CompanySummaryComponent,
    CompanyTagsComponent,
    CompanyUpdateConfirmationComponent,
    CompanyUpdateModalComponent,
    ContactComponent,
    CreateCustomTagComponent,
    DealModalComponent,
    DealsComponent,
    DisclaimerComponent,
    DownloadComponent,
    EditItemComponent,
    EditsSummaryComponent,
    ExploreComponent,
    FilterComponent,
    StatusSelectorComponent,
    SavedSearchSectorSelectorComponent,
    SavedSearchSimpleSelectorComponent,
    DetailSectionComponent,
    SectorSelectorComponent,
    SimpleSelectorComponent,
    StatusListComponent,
    HealthCheckComponent,
    JnjInformationComponent,
    JnJInformationUpdateComponent,
    JnjLogoutSuccessToastComponent,
    JnjToastComponent,
    LargeDownloadModalComponent,
    LargeNumberCurrencyPipe,
    LocationIndicatorComponent,
    GeoVisualizerComponent,
    LocationsPickerModalComponent,
    LoginComponent,
    LoginFooterComponent,
    LogInOutBannerComponent,
    MainComponent,
    MyUpdatesComponent,
    NavigationPanelComponent,
    NewCompaniesComponent,
    NewsComponent,
    Nl2BrPipe,
    NotificationsComponent,
    FundingModalComponent,
    OverviewComponent,
    PickerModalComponent,
    PillComponent,
    PublicInformationComponent,
    PublicInformationUpdateComponent,
    ProgressIndicatorComponent,
    ReviewDealFundingComponent,
    ReviewModalComponent,
    SwiperDirective,
    SearchBarComponent,
    SearchResultsComponent,
    SectorDetailsComponent,
    SectorMapComponent,
    SectorsPickerModalComponent,
    ShowMoreLessComponent,
    SimpleTabUIComponent,
    SiteHeaderComponent,
    SortControlComponent,
    SsoComponent,
    StageComponent,
    StayInTheLoopComponent,
    StrategicCollaborationComponent,
    SummaryComponent,
    TagCloudComponent,
    TagsPickerModalComponent,
    TouchTooltipDirective,
    UpdateHeaderComponent,
    UpdateSummaryComponent,
    PreferencesComponent,
    NotificationsModalComponent,
    MutableCollectionComponent,
    PreferencesSectionComponent,
    SavedSearchSummaryComponent,
    ActionModalComponent,
    SavedSearchModalComponent,
    DropdownMenuComponent,
    SavedSearchCriteriaBaseComponent,
    SavedSearchCriteriaComponent,
    SavedSearchBaseComponent,
    ModalOverlayComponent,
    ActionButtonComponent,
    ViewportContainerComponent,
    SelectInputComponent,
    ToggleInputComponent,
    StickyOnScrollComponent,
    UpdateComponent,
    LocalizedTextSnippetComponent,
    CompanyStatusIconComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      closeButton: true,
      extendedTimeOut: 0,
      positionClass: 'toast-center-center', // Ensure this positioning is overridden in src/styles.scss
      tapToDismiss: false,
      preventDuplicates: true,
      countDuplicates: true,
      resetTimeoutOnDuplicate: true,
      timeOut: 0,
      toastComponent: JnjToastComponent,
    }),
    ToastContainerModule,
    TrimModule,
    UiScrollModule,
    CommonModule,
  ],
  providers: providers,
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
