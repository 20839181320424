import { ModelBase } from './_model.base';
import { Contact, CoOwnerContact } from './contact';
import { Opportunity } from './opportunity';
import { Location } from './location';
import { LimitedDealOpportunity } from './limitedDealOpportunity';
import { Deal } from './deal';
import { Funding } from './funding';
import { nameof } from '../utility/string-util';

/**
 * @summary Note that a subset of fields are removed server-side based on a user's role, via stripSensitiveFields
 * and other fields may be removed based on feature flags.
 * @description This approach replaced a PublicCompany -> Company inheritance hierarchy that broke down when v5 introduced
 * multiple role profiles that had access to various subsets of Company properties.
 */
export class Company extends ModelBase {
    /** The date upon which a Company is considered to have engaged with JLABS.
     * The basis of a "New Company" determination, and could be a month or so in the future.
     */
    public commencementDate: Date;
    public graduationDate: Date;
    public currentRdStage: string;
    public description: string;
    public isBlueKnight: boolean;
    public isExcludeFromReporting: boolean;
    public isPublicityAgreementSigned: boolean;
    public isPubliclyOffered: boolean;
    public isQfcWinner: boolean;
    public isStealthMode: boolean;
    public locations: Location[];
    public name: string;
    public logoBase64: string;
    public logoBase64Hash: string;
    public statuses: string[];

    public opportunityIdPrimary: string;
    public opportunityLastModifiedDate: Date;
    public primaryFocus: string;
    public primarySector: string;
    public primarySubSector: string;
    public primarySectorDetail: string;
    public problem: string;
    public secondarySector: string;
    public secondaryFocus: string;
    public secondarySubSector: string;
    public secondarySectorDetail: string;
    public solution: string;
    public tags: string[];
    public totalSecuredAndContingentAmount: number;
    public wasAcquired: boolean;
    public website: string;
    public opportunityOwnerContactId: string;
    public siteHead: Contact;
    public coOwners: CoOwnerContact[];
    public ceoContactId: string;
    public companyContact: Contact;
    public womenLed: boolean;
    public minorityLed: boolean;
    public firstTimeEntrepreneur: boolean;
    public countryForDeiReporting: boolean;
    public leadershipDiversity: boolean;
    public boardAdvisorDiversity: boolean;
    public womenLedOrgLeadership: boolean;
    public womenLedBoardOfDirectorsOrEquiv: boolean;
    public topTierTeam: boolean;

    public isJPAL?: boolean;

    /** The Public version of the Company may contain a subset of Opportunities for Deal aggregation */
    public opportunities: Opportunity[];
    public limitedDealOpportunities: LimitedDealOpportunity[];

    public companyUpdateUpdatedDate: Date;
    public companyUpdateApproverEmail: string;
    public companyUpdateComments: string;

    public keyDifferentiation: string;
    public keyMgmtAndAdvBm: string;
    public priority: string;
    public progressCreatedDate: Date;
    public progressSubject: string;
    public progressUpdate: string;
    public jpalContactId: string;
    public jpalContact: Contact;
    public deals: Deal[];
    public funding: Funding[];
    public navigatorUrl: string;
    public highestLevelOfFundingSecured = 'Other';

    // BlueKnight/BARDA properties
    /**Referred to as "Alignment" in the UI */
    public companyObjective?: string;
    public alignedGoal?: string;
    public approachUsecase?: string;
    public entryExitStrategy?: string;
    public mentorship?: string;
    public engagement?: string;
    public challenges?: string;
    public priorities?: string;
    public currentTeamSizeRange?: string;
    public compositionAndGrowth?: string;
    public conferencesAndEvents?: string;
    public commercialPartnerships?: string;
    public rAndDPartnerships?: string;
    public otherPartnerships?: string;
    public overallTrl?: string;
    public alignedTrl?: string;
    public compositionOfMatter?: boolean;
    public securedIP?: boolean;
    public filedIP?: boolean;
    public addtlIPDetails?: string;
    public currencyBlueKnight?: string;
    public fundingStage?: string;
    public fundingStageDetails?: string;
    public nonDilutiveFunding?: number;
    public dilutiveFunding?: number;

    public majorMilestones?: string;
    public currentPharmaStage?: string;
    public currentMedTechStage?: string;
    public stageDetails?: string;
    public regulatoryStatus?: string;
    public anticipatedComercialProductYr?: number;
    public nonLeadRegStatus?: string;

    public static blueKnightPropertyNames: string[] = [
        nameof<Company>('companyObjective'),
        nameof<Company>('alignedGoal'),
        nameof<Company>('approachUsecase'),
        nameof<Company>('entryExitStrategy'),
        nameof<Company>('challenges'),
        nameof<Company>('priorities'),
        nameof<Company>('mentorship'),
        nameof<Company>('engagement'),
        nameof<Company>('currentTeamSizeRange'),
        nameof<Company>('compositionAndGrowth'),
        nameof<Company>('conferencesAndEvents'),
        nameof<Company>('commercialPartnerships'),
        nameof<Company>('rAndDPartnerships'),
        nameof<Company>('otherPartnerships'),
        nameof<Company>('overallTrl'),
        nameof<Company>('alignedTrl'),
        nameof<Company>('compositionOfMatter'),
        nameof<Company>('securedIP'),
        nameof<Company>('filedIP'),
        nameof<Company>('addtlIPDetails'),
        nameof<Company>('currencyBlueKnight'),
        nameof<Company>('fundingStage'),
        nameof<Company>('fundingStageDetails'),
        nameof<Company>('nonDilutiveFunding'),
        nameof<Company>('dilutiveFunding'),
        nameof<Company>('majorMilestones'),
        nameof<Company>('currentPharmaStage'),
        nameof<Company>('currentMedTechStage'),
        nameof<Company>('stageDetails'),
        nameof<Company>('regulatoryStatus'),
        nameof<Company>('anticipatedComercialProductYr'),
        nameof<Company>('nonLeadRegStatus'),
    ];

    public static isBlueKnightProperty(name: string): boolean {
        return this.blueKnightPropertyNames.includes(name);
    }
}
