import { Component, Input } from '@angular/core';
import { ComponentBase } from '../../../../_common/components/_component.base';
import { ValueWithHeader } from 'company-finder-common';

@Component({
  selector: 'detail-section',
  templateUrl: './detail-section.component.html',
  styleUrls: ['./detail-section.component.scss'],
})
export class DetailSectionComponent extends ComponentBase {
  @Input()
  public valueWithHeader: ValueWithHeader;

  public get hasValue(): boolean {
    return this.value?.length > 0;
  }

  public get header(): string {
    return this.valueWithHeader.header;
  }

  public get value(): string {
    return this.valueWithHeader?.value?.toString() ?? '';
  }
}

