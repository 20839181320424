import { Component, Input } from '@angular/core';
import { Company, LocalizedTextIds, Location } from 'company-finder-common';

import { ComponentBase } from '../_component.base';
import { StatusItem } from '../status-list/StatusItem';

@Component({
  selector: 'company-location-status',
  templateUrl: './company-location-status.component.html',
  styleUrls: ['./company-location-status.component.scss'],
})
export class CompanyLocationStatusComponent extends ComponentBase {
  // public properties
  @Input()
  public company: Company;
  @Input()
  public collapseMultipleLocations = false;
  @Input()
  /** When true, company statuses will be included with the location statuses */
  public blendStatuses = false;
  @Input()
  /** When true, adds a top line showing only company-level statuses */
  public includeCompanyStatusRow = false;
  @Input()
  public isUpdateScreen = false;
  @Input()
  public useSeparator = true;
  @Input()
  public singleLine = true;

  public isShowingLocationTooltip = false;
  public showLocationTooltip(_event: MouseEvent): void {
    // Only show the tooltip if we have collapsed down to "Multiple Locations"
    this.isShowingLocationTooltip = this.collapseLocations;
  }

  public hideLocationTooltip(_event: MouseEvent): void {
    this.isShowingLocationTooltip = false;
  }

  private _locationsToDisplay: Location[];

  public get locationsToDisplay(): Location[] {
    if (!this._locationsToDisplay) {
      if (!(this.company.locations?.length > 0)) {
        this._locationsToDisplay = [new Location()];
      } else {
        this._locationsToDisplay = this.shouldCollapse
          ? [new Location('Multiple Locations', this.multipleLocationStatuses)]
          : this.sortedLocations;
      }
    }
    return this._locationsToDisplay;
  }

  private get shouldCollapse(): boolean {
    return this.company.locations.length > 1 && this.collapseMultipleLocations;
  }

  private get multipleLocationStatuses(): string[] {
    const displayStatuses = [];
    this.company.locations?.forEach((location) => {
      const statusesToAdd = location.locationStatuses.filter(
        (status) =>
          !this._deploymentContext.getStatusMetadata(status)?.excludeOnCoalsce
      );
      displayStatuses.push(
        ...statusesToAdd.map((status) =>
          this._deploymentContext.getMetadataDisplayValue(status)
        )
      );
    });

    return [...new Set(displayStatuses)];
  }

  private _sortedLocation: Location[];

  public get sortedLocations(): Location[] {
    if (!this._sortedLocation) {
      this._sortedLocation = this.company.locations.sort((a, b) =>
        this.getSortString(a).localeCompare(this.getSortString(b))
      );
    }

    return this._sortedLocation;
  }

  private getSortString(location: Location): string {
    return location.city ?? location.name;
  }

  public get hasStatuses(): boolean {
    return (      
      this.hasLocationStatus ||
      (this.blendStatuses && this.hasCompanyStatus)
    );
  }

  private get hasLocationStatus(): boolean {
    return this._locationsToDisplay.some(
      (loc) => loc.locationStatuses?.length > 0
    );
  }

  private get hasCompanyStatus(): boolean {
    return (
      this.company.statuses?.length > 0 ||
      this.company.isQfcWinner ||
      this.company.isBlueKnight
    );
  }

  public get companyStatusItems(): StatusItem[] {
    const statuses = this.company.statuses
      ?.filter(
        (status) =>
          !(
            this._deploymentContext.companyStatusIcon &&
            status === this._deploymentContext.statusForCompanyIcon
          )
      )
      .map(
        (status) =>
          new StatusItem(
            this._deploymentContext.getStatusDisplayName(status),
            this._deploymentContext.getStatusTooltip(status)
          )
      );

    if (this.company.isQfcWinner) {
      statuses.push(new StatusItem(this.Loc(LocalizedTextIds.QFCAwardee)));
    }

    if (this.company.isBlueKnight) {
      statuses.push(
        new StatusItem(
          this.Loc(LocalizedTextIds.BlueKnight),
          null,
          '/assets/detail/blue-knight.png',
          18,
          14
        )
      );
    }

    return statuses;
  }

  public statusItems(location: Location): StatusItem[] {
    const statuses =
      location.locationStatuses?.map(
        (status) =>
          new StatusItem(
            this._deploymentContext.getStatusDisplayName(status),
            this._deploymentContext.getStatusTooltip(status)
          )
      ) ?? [];

    // In some places, like the Search Results, company and location statuses are
    // treated the same
    if (this.blendStatuses && !!this.companyStatusItems) {
      statuses.push(
        ...this.companyStatusItems
      );
    }

    const sorted = [...new Set(statuses)].sort((a, b) =>
      this.compareStatuses(a.displayText, b.displayText)
    );
    return sorted;
  }

  public compareStatuses(a: string, b: string): number {
    const aOrder = this._deploymentContext.getGroupOrderByDisplay(a);
    const bOrder = this._deploymentContext.getGroupOrderByDisplay(b);

    if (aOrder < bOrder) {
      return -1;
    } else if (bOrder < aOrder) {
      return 1;
    }

    return 0;
  }

  public get collapseLocations(): boolean {
    return this.collapseMultipleLocations && this.company.locations?.length > 1;
  }

  public getLocationDisplayValue(location: Location): string {
    return !location.city || location.name === location.city
      ? location.name
      : `${location.city} (${location.name})`;
  }
}
