import { Injectable } from '@angular/core';
import { DeploymentContext } from '../../../_common/utilities/deployment-context/deployment-context';
import {
  Company,
  Deal,
  Funding,
  ICompanyUpdate,
  IDealUpdate,
  IFundingUpdate,
  IModelEntity,
  IUpdateField,
  LocalizedTextIds,
  ProcessedStatus,
  TAG_SEPARATOR,
  UpdateStatus,
  applyUpdate,
  getHeaderIdForProperty,
  isBooleanProperty,
  isCompanyContactTitle,
  isFirstTimeEntrepreneurProperty,
} from 'company-finder-common';
import _ from 'lodash';
import {
  AddDeleteEditUpdate,
  EditedContent,
  ReviewableUpdate,
  ReviewableUpdateField,
  SelfUpdateMode,
  SubmittedUpdate,
  TagReviewModification,
  TagReviewModificationType,
} from '../company-update.interface';
import {
  compareCompanies,
  Diff,
  isUpdateAChange,
} from '../utils';
import {
  ApplicationContext,
  NewDealOrFundingPrefix,
} from '../../../_common/utilities/application-context/application-context';
import { CompanyService } from '../../../_common/services/company/company.service';
import { ReviewEditsService } from './review-edits.service';
import {
  ensureMappingForBooleans,
  formatSubmissionContent,
} from '../utils/data-format-utils';
import { NotificationsComponent } from '../components/notifications/notifications.component';
import { TitleAndMetadata } from '../../../_common/utilities/title-and-metadata/title-and-metadata';
import { AuthnService } from '../../../_common/services/authn/authn.service';
import { LogService } from '../../../_common/services/log/log.service';

@Injectable()
export class CompanyUpdateService {
  private _company: Company;
  private _companyWithPending: Company;

  public get company(): Company {
    return this._company;
  }
  public set company(value: Company) {
    this._company = value;
  }

  public get companyWithPending(): Company {
    return this._companyWithPending;
  }
  public set companyWithPending(value: Company) {
    this._companyWithPending = value;
  }

  public companyClone: Company;
  public companyWithMostRecentEdit: Company;
  public companyBeforeAnyChanges: Company;
  public isEditingProperty: { [propName: string]: boolean } = {};
  public isEditingReviewComments = false;
  public updateForReview: ReviewableUpdate;
  public selfUpdateMode: SelfUpdateMode;
  public haveDealsChanged = false;
  public haveFundingsChanged = false;
  public readonly progressUpdateString = 'progressUpdate';
  public pendingUpdate: ICompanyUpdate;
  public progressUpdate: string;
  public progressUpdateDate: Date;
  public reviewComments: string;
  public submittedUpdate: SubmittedUpdate = {
    simpleUpdates: [],
    diversityUpdates: [],
    addDeleteUpdates: [],
    addDeleteEditUpdates: [],
  };
  public tagReviews: TagReviewModification[] = [];
  public updateSubmitted = false;
  public reviewSubmitted = false;
  public isShareWithFollowers: boolean = undefined;

  public dealToModify: Deal = new Deal();
  public fundingToModify: Funding = new Funding();

  constructor(
    private _deploymentContext: DeploymentContext,
    private _companyService: CompanyService,
    private _reviewEditsService: ReviewEditsService,
    private _authnService: AuthnService,
    private titleAndMetadata: TitleAndMetadata,
    private logService: LogService
  ) { }

  public initializeCompany(company: Company): void {
    this.updateForReview = {
      updateFields: [],
      dealUpdates: [],
      fundingUpdates: [],
    };
    // Grab the company data.
    this.company = _.merge(new Company(), company);
    this.titleAndMetadata.setPageTitle(this.company.name);
    // Clone the stored company data to diff against later.
    this.companyClone = this.cloneCompany(this.company);

    this.companyBeforeAnyChanges = _.merge({}, this.company);
    this.companyWithPending = _.merge({}, this.company);
    this.companyWithMostRecentEdit = _.merge({}, this.company);
  }

  public async initializeUpdateData(
    initializeCompanyBeforeUpdates: boolean = true
  ): Promise<void> {
    this.haveDealsChanged = false;
    this.haveFundingsChanged = false;

    await this.applySavedOrPendingUpdateData();

    this.companyWithPending = this.cloneCompany(this.company);
    this.companyWithMostRecentEdit = this.cloneCompany(this.company);
  }

  public async saveCompany(doSubmit: boolean = true): Promise<ICompanyUpdate> {
    // If there's a progressUpdate value, make sure the company gets it.
    if (this.progressUpdate) {
      this.company.progressUpdate = this.progressUpdate.trim();
    }

    const companyUpdate = this.getCompanyUpdateForCurrentSession();
    ensureMappingForBooleans(companyUpdate);
    // Don't submit any empty progressUpdates
    this.ensureProgressUpdate(companyUpdate);
    this.checkUpdatesForUI(companyUpdate);

    // If just doing a save and not submitting, use Saved status instead of Pending
    companyUpdate.status = doSubmit ? UpdateStatus.Pending : UpdateStatus.Saved;

    const savedCompanyUpdate = await this._companyService.updateCompany(
      companyUpdate
    );

    // pending update maybe should be renamed to savedOrPendingUpdate,
    // but need to verify that we don't need to distinguish these.
    // If we do, we can always use a method which looks at .status to see if it is Saved or Pending.
    this.pendingUpdate = savedCompanyUpdate;

    this.companyClone = this.cloneCompany(this.company);
    this.companyWithPending = _.merge({}, this.company);
    this.companyWithMostRecentEdit = _.merge({}, this.company);

    return savedCompanyUpdate;
  }

  public async updateCompany(): Promise<void> {
    await this.saveCompany(true);

    this.titleAndMetadata.setPageTitle(this.company.name);

    this.updateSubmitted = true;
    this._deploymentContext.ensureScrolledToTop();
  }

  public async submitReview(): Promise<void> {
    // Manage and review changes to the tags
    this.tagReviews.map((tagReview) => {
      if (tagReview.value) {
        if (tagReview.modificationType === TagReviewModificationType.Preserve) {
          this.company.tags.push(tagReview.tag);
        }
        if (tagReview.modificationType === TagReviewModificationType.Exclude) {
          _.remove(this.company.tags, (aTag) => aTag === tagReview.tag);
        }
      }
    });

    // Compose the update
    const companyUpdate = this.getCompanyUpdateForCurrentSession();

    ensureMappingForBooleans(companyUpdate);
    // Don't submit any empty progressUpdates
    this.ensureProgressUpdate(companyUpdate);

    // This check makes sure the confirmation screen gets any edits the reviewer applied.
    this.checkUpdatesForUI(companyUpdate);
    companyUpdate.approverEmailAddress = this._authnService.userId;

    // FUTURE: Consider pushing the comments & isNoteworthy up into the main company update form?
    //         Note that they would only be available to reviewers, not editors.
    companyUpdate.comments = this.reviewComments;
    companyUpdate.isNoteworthy = this.isShareWithFollowers;
    await this._reviewEditsService.submitReview(companyUpdate);
    this.reviewSubmitted = true;
  }

  public getCompanyUpdateForCurrentSession(): ICompanyUpdate {
    if (this.isUpdateFieldSet(this.progressUpdateString)) {
      // We're working on a pending update, so allow an empty progressUpdate to clear the pending progressUpdate.
      if (
        this.progressUpdate !== this.companyWithMostRecentEdit.progressUpdate
      ) {
        this.companyWithMostRecentEdit.progressUpdate =
          this.progressUpdate?.trim();
      }
    } else {
      // No pending update, so don't allow an empty progressUpdate because they aren't clearing anything.
      if (
        this.progressUpdate &&
        this.progressUpdate !== this.companyWithPending.progressUpdate
      ) {
        this.companyWithMostRecentEdit.progressUpdate =
          this.progressUpdate?.trim();
      }
    }

    return this.buildCompanyUpdate(
      this.companyWithMostRecentEdit,
      this.companyWithPending
    );
  }

  // We don't want empty progressUpdates submitted, but we do want to allow the user to remove
  // the update in a pending update without having to retract the entire submission. To accomplish
  // this we let all updates get queued up, but then prune the progressUpdate if it's empty, so we
  // don't have to keep track of the original, a pending one, and the cleared one separately.
  // Management of the various company caches are more complex than we want to deal with right now,
  // so it's easier just to do it immediately prior to the submission.
  private ensureProgressUpdate(companyUpdate: ICompanyUpdate): void {
    const progressUpdateUpdateField = companyUpdate.updateFields.find(
      (updateField) => updateField.name === this.progressUpdateString
    );
    if (progressUpdateUpdateField && !progressUpdateUpdateField.newValue) {
      _.remove(
        companyUpdate.updateFields,
        (updateField) => updateField.name === progressUpdateUpdateField.name
      );
      this.updateForReview.updateFields[this.progressUpdateString] = undefined;
    }
  }

  public async applySavedOrPendingUpdateData(): Promise<void> {
    this.pendingUpdate =
      await this._companyService.getSavedOrPendingUpdateByOpportunityId(
        this.company.opportunityIdPrimary
      );
    if (this.pendingUpdate) {
      // If there is a pending update, apply the update to the base company
      applyUpdate(this.pendingUpdate, this.company);

      this.checkUpdatesForUI(this.pendingUpdate);

      this.haveDealsChanged = this.pendingUpdate.dealUpdates?.length > 0;
      this.haveFundingsChanged = this.pendingUpdate.fundingUpdates?.length > 0;

      // Initialize progressUpdate, because it's managed a little differently than the other company properties.
      if (this.isUpdateFieldSet(this.progressUpdateString)) {
        this.progressUpdate =
          this.updateForReview.updateFields[this.progressUpdateString].newValue;
        const progressUpdateUpdateField = this.pendingUpdate.updateFields.find(
          (item) => item.name === this.progressUpdateString
        );
        if (progressUpdateUpdateField) {
          const date = new Date(progressUpdateUpdateField.updatedDate);
          this.progressUpdateDate = (date as any).toLocaleString('en-US', {
            dateStyle: 'medium',
          });
        }
      }
    } else {
      this.progressUpdate = undefined;
      this.updateForReview = {
        updateFields: [],
        dealUpdates: [],
        fundingUpdates: [],
      };
    }
  }

  public checkUpdatesForUI(update: ICompanyUpdate): void {
    this.submittedUpdate = {
      simpleUpdates: [],
      diversityUpdates: [],
      addDeleteUpdates: [],
      addDeleteEditUpdates: [],
    };
    function newUpdateField(
      updateField: IUpdateField,
      displayName: string = null
    ): ReviewableUpdateField {
      return {
        displayName: displayName || updateField.name,
        isSet: isUpdateAChange(updateField),
        newValue: updateField.newValue,
        oldValue: updateField.oldValue,
      };
    }
    if (update?.updateFields) {
      update.updateFields.map((updateField) => {
        // Handle new values that might be booleans, zeroes, and things like that which might "hide" the update
        this.updateForReview.updateFields[updateField.name] = newUpdateField(
          updateField,
          this.displayNameForProperty(updateField.name)
        );
        if (updateField.name === 'tags') {
          // Restore the separated values into string[]
          const newValueArray = updateField.newValue.split(TAG_SEPARATOR);
          const oldValueArray = updateField.oldValue.split(TAG_SEPARATOR);
          const added = newValueArray.filter(
            (item) => !oldValueArray.includes(item)
          );
          const deleted = oldValueArray.filter(
            (item) => !newValueArray.includes(item)
          );
          if (added.length || deleted.length) {
            this.submittedUpdate.addDeleteUpdates.push({
              added: added,
              deleted: deleted,
              displayName: this.displayNameForProperty(updateField.name),
              propertyName: updateField.name,
            });
          }
        } else {
          const isDei = this.isDeiProperty(updateField.name);
          const isFirstTimeEntrepreneur = isFirstTimeEntrepreneurProperty(
            updateField.name
          );
          let title: string;
          if (updateField.name === this.progressUpdateString) {
            // NOTE: GVio confirmed that Brittany said that progressUpdate's title should always be as hardcoded below.
            title = this._deploymentContext.Loc(
              LocalizedTextIds.CompanyUpdateServiceNewUpdate
            );
          }
          if (isFirstTimeEntrepreneur) {
            const val = parseInt(updateField.newValue, 10);
            const booleanValue = isNaN(val)
              ? updateField.newValue.toString() === 'true'
                ? true
                : false
              : !!val;
            this.submittedUpdate.diversityUpdates.push({
              booleanValue: booleanValue,
              content: updateField.name,
              isLogo: false,
              displayName: this.displayNameForProperty(updateField.name),
              propertyName: updateField.name,
              title: title,
            });
          } else if (isDei) {
            const content = updateField.newValue
              // removes any country prefix that precedes a " - ", accounting for ; separated values
              // NOTE: This needs to be kept in sync with jnj-information-update.component.ts applyDeiCountryPrefixes
              .replace(/[^;]* - /gm, '')
              .replace(/;/gm, '; '); // use a regex to ensure all occurences are replaced
            // FUTURE: At least one dei option has a different display string than value, and this will show the value to the user.
            //  Consider a reverse lookup or some other approach. Wasn't worth the effort at the time since the values are similar enough.
            this.submittedUpdate.simpleUpdates.push({
              content: content,
              isLogo: updateField.name === 'logoBase64',
              displayName: this.displayNameForProperty(updateField.name),
              propertyName: updateField.name,
              title: title,
            });
          } else {
            this.submittedUpdate.simpleUpdates.push({
              content: updateField.newValue,
              isLogo: updateField.name === 'logoBase64',
              displayName: this.displayNameForProperty(updateField.name),
              propertyName: updateField.name,
              title: title,
            });
          }
        }
      });
    }

    if (update?.dealUpdates) {
      const addDeleteEditUpdate = {
        added: [],
        deleted: [],
        edited: [],
        displayName: this._deploymentContext.Loc(
          LocalizedTextIds.CompanyDetailsDeals
        ),
      } as AddDeleteEditUpdate;
      update.dealUpdates.map((dealUpdate) => {
        if (!this.updateForReview.dealUpdates[dealUpdate.modelId]) {
          this.updateForReview.dealUpdates[dealUpdate.modelId] = {
            updateFields: [],
          };
        }
        let editedDealUpdate;
        if (dealUpdate.isDeletedDeal) {
          const deletedDeal = this.companyClone.deals.find(
            (deal) => deal.dealId === dealUpdate.modelId
          );
          if (deletedDeal) {
            // Existing deals would be in the companyClone, new ones in the user's current session would not,
            // and don't need to be included in the addDeleteEditUpdate.
            addDeleteEditUpdate.deleted.push(deletedDeal.dealParty);
          }
        } else {
          dealUpdate.updateFields.map((updateField) => {
            this.updateForReview.dealUpdates[dealUpdate.modelId].updateFields[
              updateField.name
            ] = newUpdateField(updateField);
            // Short-circuit 'added,' because we only need to display the dealParty, similar to delete above.
            if (dealUpdate.isNewDeal && updateField.name === 'dealParty') {
              addDeleteEditUpdate.added.push(updateField.newValue);
            }
            // 'Edited' deals, however, have to show what changed.
            if (!dealUpdate.isNewDeal && !dealUpdate.isDeletedDeal) {
              if (!editedDealUpdate) {
                editedDealUpdate = {
                  updates: [],
                } as EditedContent;
              }
              const deal = this.company.deals.find(
                (d) => d.dealId === dealUpdate.modelId
              );
              // This is an edited deal
              if (updateField.name === 'dealParty') {
                editedDealUpdate.title = updateField.newValue;
              } else {
                editedDealUpdate.title = deal.dealParty || '';
              }
              const content = formatSubmissionContent(
                updateField.name,
                updateField.newValue,
                deal.amountCurrency
              );
              editedDealUpdate.updates.push({
                content: content,
                displayName: this.displayNameForProperty(updateField.name),
              });
            }
          });
        }
        if (editedDealUpdate) {
          addDeleteEditUpdate.edited.push(editedDealUpdate);
        }
        addDeleteEditUpdate.modelId = dealUpdate.modelId;
      });
      if (
        addDeleteEditUpdate.added.length ||
        addDeleteEditUpdate.deleted.length ||
        addDeleteEditUpdate.edited.length
      ) {
        this.submittedUpdate.addDeleteEditUpdates.push(addDeleteEditUpdate);
      }
    }
    if (update?.fundingUpdates) {
      const addDeleteEditUpdate = {
        added: [],
        deleted: [],
        edited: [],
        displayName: this._deploymentContext.Loc(
          LocalizedTextIds.CompanyDetailsFunding
        ),
      } as AddDeleteEditUpdate;
      update.fundingUpdates.map((fundingUpdate) => {
        if (!this.updateForReview.fundingUpdates[fundingUpdate.modelId]) {
          this.updateForReview.fundingUpdates[fundingUpdate.modelId] = {
            updateFields: [],
          };
        }
        let editedFundingUpdate;
        if (fundingUpdate.isDeletedFunding) {
          const deletedFunding = this.companyClone.funding.find(
            (f) => f.fundingId === fundingUpdate.modelId
          );
          if (deletedFunding) {
            // Existing funding would be in the companyClone, new ones in the user's current session would not,
            // and don't need to be included in the addDeleteEditUpdate.
            addDeleteEditUpdate.deleted.push(deletedFunding.fundingParty);
          }
        } else {
          fundingUpdate.updateFields.map((updateField) => {
            this.updateForReview.fundingUpdates[
              fundingUpdate.modelId
            ].updateFields[updateField.name] = newUpdateField(updateField);
            // Short-circuit 'added,' because we only need to display the fundingParty, similar to delete above.
            if (
              fundingUpdate.isNewFunding &&
              updateField.name === 'fundingParty'
            ) {
              addDeleteEditUpdate.added.push(updateField.newValue);
            }
            // 'Edited' fundings, however, have to show what changed.
            if (
              !fundingUpdate.isNewFunding &&
              !fundingUpdate.isDeletedFunding
            ) {
              if (!editedFundingUpdate) {
                editedFundingUpdate = {
                  updates: [],
                } as EditedContent;
              }
              const funding = this.company.funding.find(
                (f) => f.fundingId === fundingUpdate.modelId
              );
              // This is an edited funding
              if (updateField.name === 'fundingParty') {
                editedFundingUpdate.title = updateField.newValue;
              } else {
                editedFundingUpdate.title = funding.fundingParty || '';
              }
              const content = formatSubmissionContent(
                updateField.name,
                updateField.newValue,
                funding.raisedCurrency
              );
              editedFundingUpdate.updates.push({
                content: content,
                displayName: this.displayNameForProperty(updateField.name),
              });
            }
          });
        }
        if (editedFundingUpdate) {
          addDeleteEditUpdate.edited.push(editedFundingUpdate);
        }
        addDeleteEditUpdate.modelId = fundingUpdate.modelId;
      });
      if (
        addDeleteEditUpdate.added.length ||
        addDeleteEditUpdate.deleted.length ||
        addDeleteEditUpdate.edited.length
      ) {
        this.submittedUpdate.addDeleteEditUpdates.push(addDeleteEditUpdate);
      }
    }
  }

  public composeCompanyUpdate(diffs: Diff): ICompanyUpdate {
    if (!diffs) {
      return null;
    }
    const keys = Object.keys(diffs);

    let companyUpdateFields: IUpdateField[]
      = this.composeUpdateFields(
        keys,
        this.company,
        this.companyBeforeAnyChanges);


    const dealUpdates =
      this.getDealOrFundingUpdates(diffs?.deals, 'deal') as IDealUpdate[];

    const fundingUpdates =
      this.getDealOrFundingUpdates(diffs?.funding, 'funding') as IFundingUpdate[];

    return {
      id: undefined,
      createdDate: undefined,
      updatedDate: undefined,
      modelId: this.company.opportunityIdPrimary,
      approverEmailAddress: undefined,
      comments: undefined,
      isNoteworthy: false,
      processed: ProcessedStatus.NotStarted,
      status: UpdateStatus.Pending,
      updateFields: companyUpdateFields,
      dealUpdates: dealUpdates,
      fundingUpdates: fundingUpdates,
    };
  }

  private valueOrNullbyKey(obj: Company | Deal | Funding, key: string) {
    if (!obj) {
      return null;
    }

    const keyForValue = key === 'companyContactTitle'
      ? 'companyContact.title'
      : key;

    return keyForValue !== 'tags'
      ? this.getValueByKey(obj, keyForValue)
      : obj[keyForValue].sort().join(TAG_SEPARATOR)
  }

  public composeUpdateFields(
    keys: string[],
    updated: Company | Deal | Funding,
    original: Company | Deal | Funding,
    isCompany: boolean = true,
    navTableName: string = 'company_update'
  ): IUpdateField[] {
    return keys?.reduce((result, key) => {

      if (key === 'deals' || key === 'funding') {
        return result;
      }

      // FUTURE: backend_destination and nav_table_name are really for the benefit of the ETL team, and probably belong
      // as close to the persistence layer as possible, but it felt potentially confusing & error
      // prone to separate them from their UpdateField brethren. At some point it might make sense to
      // push a lot of the company update composition to the API, which would mitigate these concerns,
      // albeit at the price of a fairly significant refactor, and possible loss of information on the
      // client which might be currently benefiting from client-side company update composition.
      result.push({
        emailAddress: this._authnService.userId,
        name: key,
        nav_table_name: navTableName,
        newValue: this.valueOrNullbyKey(updated, key),
        oldValue: this.valueOrNullbyKey(original, key) ?? undefined,
        processed: ProcessedStatus.NotStarted,
        status: UpdateStatus.Pending,
        backend_destination: isCompany && Company.isBlueKnightProperty(key)
          ? 'blueKnight'
          : 'jforce',
      });

      return result;
    }, []) ?? [];
  }

  public getCurrentUpdate(): ICompanyUpdate {
    return this.buildCompanyUpdate(this.company, this.companyClone);
  }

  public isCurrentUpdateApproved(
    notificationsComponent: NotificationsComponent
  ): boolean {
    return (
      notificationsComponent?.hasNotification &&
      notificationsComponent.isApproved()
    );
  }

  public isNewDealOrFunding(obj: Deal | Funding): boolean {
    return obj['dealId']?.startsWith(NewDealOrFundingPrefix) || obj['fundingId']?.startsWith(NewDealOrFundingPrefix);
  }

  public buildCompanyUpdate(
    companyA: Company,
    companyB: Company
  ): ICompanyUpdate {
    const diff = compareCompanies(companyA, companyB);
    const thisUpdate = this.composeCompanyUpdate(diff);
    return this.pendingUpdate && thisUpdate
      ? this.mergeCompanyUpdates(this.pendingUpdate, thisUpdate)
      : thisUpdate;
  }

  public applyTagReviewModification(tagReview: TagReviewModification): void {
    const existingTagReview = this.tagReviews.find(
      (aTagReview) => aTagReview.tag === tagReview.tag
    );
    if (!existingTagReview) {
      this.tagReviews.push(tagReview);
    } else {
      existingTagReview.value = tagReview.value;
    }
  }

  public async applyUpdateRetracted(): Promise<void> {
    this.initializeCompany(_.merge({}, this.companyBeforeAnyChanges));
    // The update was just retracted, so there's no pendingUpdate for initializeUpdateData(),
    // but it does reset some things for us.
    await this.initializeUpdateData();
  }

  private getModelId(update: IModelEntity): string {
    // The modelId will be the same for all edited diffs in this deal or funding.
    return update[Object.keys(update)[0]]?.modelId ?? undefined;
  }

  private getDealOrFunding(
    company: Company,
    type: 'deal' | 'funding',
    update: IModelEntity
  ): Deal | Funding {
    const modelId = this.getModelId(update);
    const property = type === 'deal' ? 'deals' : type;
    const dealOrFundingArray = company[`${property}`];
    // If there is no modelId, as would be the case for new deals or funding, fall back on dealParty.
    return dealOrFundingArray.find((dealOrFunding: Deal | Funding) => {
      return !!modelId
        ? dealOrFunding[`${type}Id`] === modelId
        : dealOrFunding[`${type}Party`] === update[`${type}Party`].newValue;
    });
  }

  public getDealOrFundingUpdates(
    updates: IModelEntity[],
    type: 'deal' | 'funding'
  ): Partial<IDealUpdate | IFundingUpdate>[] {
    if (!updates) {
      return undefined;
    }

    const dealOrFundingUpdates: Partial<IDealUpdate | IFundingUpdate>[] = [];
    updates.forEach((update: IFundingUpdate | IDealUpdate) => {

      // N.B each "Update" consits of the sum total of update fields for that deal/funding
      const dealOrFundingKeys = Object.keys(update);
      const updatedDealOrFunding = this.getDealOrFunding(this.company, type, update);
      const originalDealOrFunding = this.getDealOrFunding(this.companyBeforeAnyChanges, type, update);

      const dealOrFundingUpdateFields = updatedDealOrFunding
        ? this.composeUpdateFields(
          dealOrFundingKeys,
          updatedDealOrFunding,
          originalDealOrFunding,
          false,
          `${type}_update`)
        : [];

      const isUpdatedDeal = type === 'deal' && updatedDealOrFunding;
      const isUpdatedFunding = type === 'funding' && updatedDealOrFunding;

      const dealOrFundingUpdate: Partial<IDealUpdate | IFundingUpdate> = {
        id: undefined,
        createdDate: undefined,
        updatedDate: undefined,
        companyUpdate: undefined,
        modelId: this.getModelId(update),
        updateFields: dealOrFundingUpdateFields,
        isNewDeal: isUpdatedDeal
          ? this.isNewDealOrFunding(updatedDealOrFunding)
          : undefined,
        isNewFunding: isUpdatedFunding
          ? this.isNewDealOrFunding(updatedDealOrFunding)
          : undefined,
        isDeletedDeal: isUpdatedDeal
          ? updatedDealOrFunding.isDeleted
          : type === 'deal' ? true : undefined,
        isDeletedFunding: isUpdatedFunding
          ? updatedDealOrFunding.isDeleted
          : type === 'funding' ? true : undefined,
      };

      dealOrFundingUpdates.push(dealOrFundingUpdate);
    });

    return dealOrFundingUpdates;
  }

  private displayNameForProperty(propertyName: string): string {
    return isFirstTimeEntrepreneurProperty(propertyName)
      ? this._deploymentContext.Loc(LocalizedTextIds.CommunityAndDiversityTitle)
      : this._deploymentContext.Loc(getHeaderIdForProperty(propertyName)) ?? this._deploymentContext.LocWithPrefix(propertyName, 'UpdateField');
  }

  public isUpdateFieldSet(propertyName: string): boolean {
    return this.updateForReview.updateFields[propertyName]?.isSet;
  }

  public isProgressUpdateSet(): boolean {
    return this.isUpdateFieldSet(this.progressUpdateString);
  }

  public cloneCompany(company: Company): Company {
    return _.cloneDeep(company);
  }

  public revertItemEdit(propertyName: string): void {
    if (isCompanyContactTitle(propertyName)) {
      if (this.companyWithPending.companyContact) {
        if (this.company.companyContact) {
          this.company.companyContact.title = _.cloneDeep(
            this.companyWithPending.companyContact.title
          );
        }
        if (this.companyWithMostRecentEdit.companyContact) {
          this.companyWithMostRecentEdit.companyContact.title = _.cloneDeep(
            this.companyWithPending.companyContact.title
          );
        }
      }
    } else {
      this.company[propertyName] = _.cloneDeep(
        this.companyWithPending[propertyName]
      );
      this.companyWithMostRecentEdit[propertyName] = _.cloneDeep(
        this.companyWithPending[propertyName]
      );
      if (propertyName === this.progressUpdateString) {
        this.progressUpdate = this.companyWithPending.progressUpdate;
      }
    }
    this.isEditingProperty[propertyName] = false;
    this._reviewEditsService.currentEditItemProperty = null;
    this.updateForReview.updateFields[propertyName] = undefined;
  }

  public revertMultipleEdits(propertyNames: string[], parent: string): void {
    propertyNames.forEach((propertyName) => {
      this.company[propertyName] = this.companyWithPending[propertyName];
      this.companyWithMostRecentEdit[propertyName] =
        this.companyWithPending[propertyName];
    });
    this.isEditingProperty[parent] = false;
    this._reviewEditsService.currentEditItemProperty = null;
  }

  public async revertChanges(): Promise<void> {
    // Reset the company
    this.company = _.merge({}, this.companyClone);
    // Restore pending update.
    await this.initializeUpdateData(false);
  }

  private getValueByKey(obj: any, keyStr: string): any {
    const dotIndex = keyStr.indexOf('.');
    if (dotIndex > 0) {
      const key = keyStr.slice(0, dotIndex);
      return this.getValueByKey(obj[key], keyStr.slice(dotIndex + 1));
    } else {
      if (isBooleanProperty(keyStr)) {
        return obj[keyStr];
      }
      return obj[keyStr] || '';
    }
  }

  public isDeiProperty(propertyName: string): boolean {
    return (
      propertyName === 'leadershipDiversity' ||
      propertyName === 'boardAdvisorDiversity'
    );
  }

  public wouldBeEmptyCompanyUpdate(companyUpdate: ICompanyUpdate): boolean {
    // Would the CompanyUpdate have no more updates of the specified type if we delete that type of update?
    const wouldHaveNoUpdatesOfType =
      this.wouldHaveNoUpdatesFor(companyUpdate.dealUpdates) &&
      this.wouldHaveNoUpdatesFor(companyUpdate.fundingUpdates);

    return (
      (!companyUpdate.updateFields ||
        companyUpdate.updateFields.length === 0) &&
      wouldHaveNoUpdatesOfType
    );
  }

  private mergeCompanyUpdates(
    target: ICompanyUpdate,
    source: ICompanyUpdate
  ): ICompanyUpdate {
    // FUTURE: Is there a more streamlined way to merge these updates?
    if (target) {
      target = this.sortUpdates(target);
    }

    if (source) {
      source = this.sortUpdates(source);
    } else {
      return target;
    }

    if (target && source) {
      // Merge each item in each array
      this.mergeUpdateFields(target.updateFields, source.updateFields);
    }

    if (source.dealUpdates) {
      source.dealUpdates.forEach((sdu: IDealUpdate) => {
        if (!target.dealUpdates) {
          this.logService.logOnServer(
            `During merge, target.dealUpdates was null or undefined`,
            'warn'
          );
          target.dealUpdates = [];
        }
        const targetDealUpdate = target.dealUpdates.find(
          (tdu) => tdu.modelId === sdu.modelId
        );
        if (targetDealUpdate) {
          this.mergeUpdateFields(
            targetDealUpdate.updateFields,
            sdu.updateFields
          );
          if (sdu.isNewDeal !== undefined) {
            targetDealUpdate.isNewDeal = sdu.isNewDeal;
          }
          if (sdu.isDeletedDeal !== undefined) {
            targetDealUpdate.isDeletedDeal = sdu.isDeletedDeal;
          }
        } else {
          target.dealUpdates.push(sdu);
        }
      });
    }

    if (source.fundingUpdates) {
      source.fundingUpdates.forEach((sfu: IFundingUpdate) => {
        if (!target.fundingUpdates) {
          this.logService.logOnServer(
            `During merge, target.fundingUpdates was null or undefined`,
            'warn'
          );
          target.fundingUpdates = [];
        }
        const targetFundingUpdate = target.fundingUpdates.find(
          (tfu) => tfu.modelId === sfu.modelId
        );
        if (targetFundingUpdate) {
          this.mergeUpdateFields(
            targetFundingUpdate.updateFields,
            sfu.updateFields
          );
          if (sfu.isNewFunding !== undefined) {
            targetFundingUpdate.isNewFunding = sfu.isNewFunding;
          }
          if (sfu.isDeletedFunding !== undefined) {
            targetFundingUpdate.isDeletedFunding = sfu.isDeletedFunding;
          }
        } else {
          target.fundingUpdates.push(sfu);
        }
      });
    }

    return target;
  }

  private mergeUpdateFields(
    target: IUpdateField[],
    source: IUpdateField[]
  ): void {
    source.forEach((suf: IUpdateField) => {
      let targetUpdateField = target.find((tuf) => tuf.name === suf.name);
      if (targetUpdateField) {
        // ADJQ-1190: This feels like a hacky way to avoid the approver ID stepping over
        //  the emailAddress, other than when the approver actually changed a field value.
        let originalEmailAddress = null;
        if (suf.newValue === targetUpdateField.newValue) {
          originalEmailAddress = targetUpdateField.emailAddress;
        }
        targetUpdateField = _.merge(targetUpdateField, suf);

        if (originalEmailAddress) {
          targetUpdateField.emailAddress = originalEmailAddress;
        }
      } else {
        target.push(suf);
      }
    });
  }

  private sortUpdates(update: ICompanyUpdate): ICompanyUpdate {
    if (update.updateFields) {
      update.updateFields = update.updateFields.sort(
        (uf1: any, uf2: any) => uf1.name - uf2.name
      );
    }

    if (update.dealUpdates) {
      update.dealUpdates = update.dealUpdates.sort(
        (du1: any, du2: any) => du1.id - du2.id
      );
      update.dealUpdates.forEach((du) => {
        du.updateFields = du.updateFields.sort(
          (uf1: any, uf2: any) => uf1.name - uf2.name
        );
      });
    }

    if (update.fundingUpdates) {
      update.fundingUpdates = update.fundingUpdates.sort(
        (fu1: any, fu2: any) => fu1.id - fu2.id
      );
      update.fundingUpdates.forEach((fu) => {
        fu.updateFields = fu.updateFields.sort(
          (uf1: any, uf2: any) => uf1.name - uf2.name
        );
      });
    }

    return update;
  }

  private wouldHaveNoUpdatesFor(
    updates: IDealUpdate[] | IFundingUpdate[] | undefined
  ): boolean {
    // Will the CompanyUpdate have no more updates of the specified type if we delete that type of update?
    return !updates || updates.length === 1; // deleting one will leave zero
  }
}
