import { Component, Input } from '@angular/core';
import { ComponentBase } from '../../../../_common/components/_component.base';
import { Company } from 'company-finder-common';

@Component({
  selector: 'blue-knight-information',
  templateUrl: './blue-knight-information.component.html',
  styleUrls: ['./blue-knight-information.component.scss'],
})
export class BlueKnightInformationComponent extends ComponentBase {
  @Input()
  public company: Company;

  public properties: string[] = [
    'companyObjective',
    'alignedGoal',
    'approachUsecase',
    'entryExitStrategy',
    'mentorship',
    'engagement',
    'currentTeamSizeRange',
    'compositionAndGrowth',
    'conferencesAndEvents',
    'overallTrl',
    'alignedTrl',
  ];
}

