import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ApproveDeclineComponent } from '../approve-decline/approve-decline.component';
import { CompanyService } from '../../../../_common/services/company/company.service';
import { CompanyUpdateService } from '../../services/company-update.service';
import { NotificationsComponent } from '../notifications/notifications.component';
import { UpdateComponentBaseWithEditItems } from '../../UpdateComponentBaseWithEditItems';
import _ from 'lodash';
import {
  Deal,
  Funding,
  ICompanyUpdate,
  TechnologyReadinessLevel,
} from 'company-finder-common';

// service/utility imports
import { ReviewEditsService } from '../../services/review-edits.service';
import { DeploymentContext } from '../../../../_common/utilities/deployment-context/deployment-context';
import { EditItemChoice, MultiChoiceItemChange } from '../../company-update.interface';
import { EditItemComponent } from '../edit-item/edit-item.component';

@Component({
  selector: 'blue-knight-information-update',
  templateUrl: './blue-knight-information-update.component.html',
  styleUrls: ['./blue-knight-information-update.component.scss'],
})
export class BlueKnightInformationUpdateComponent
  extends UpdateComponentBaseWithEditItems
  implements OnDestroy, OnInit
{
  // I'd really rather have something cleaner than passing in the component,
  // but I also don't want this current refactor to grow bigger than it
  // already needs to be. I'm adding a backlog story to revisit this, and I'll
  // add the link to it here once it exists (not doing it now in case I change my approach)
  @Input()
  public notificationsComponent: NotificationsComponent;

  @ViewChildren(ApproveDeclineComponent)
  public approveDeclineComponents: QueryList<ApproveDeclineComponent>;
  @ViewChild('alignedGoalEditItem', { static: true })
  public alignedGoalEditItem: EditItemComponent;

  public get trlList(): TechnologyReadinessLevel[] {
    return this._deploymentContext.referenceValueData.technologyReadinessLevelList;
  }
  
  public constructor(
    dc: DeploymentContext,
    _companyService: CompanyService,
    _companyUpdateService: CompanyUpdateService
  ) {
    super(dc, _companyUpdateService, _companyService);    
  }

  public async ngOnInit(): Promise<void> {
    this.setAllSubscriptions();
  }

  public ngOnDestroy(): void {
    this._subscriptions?.unsubscribe();
  }

  public get goalChoices(): EditItemChoice[]  {
    return this._deploymentContext.referenceValueData.blueKnightGoals;
  }

  public get alignedGoal(): string {
    const formControl = this.companyEditForm.get('alignedGoal');
    return formControl?.value;
  }

  public set alignedGoal(value: string) {
    const formControl = this.companyEditForm.get('alignedGoal');
    formControl?.setValue(value);
  }

  public get teamSizeOptions(): EditItemChoice[]  {
    return this._deploymentContext.referenceValueData.teamSizeOptions;
  }

  public isCurrentUpdateApproved(): boolean {
    return this._companyUpdateService.isCurrentUpdateApproved(
      this.notificationsComponent
    );
  }

  public setAllSubscriptions(): void {
    this.alignedGoalEditItem.multiChoiceValueChangedSubject.subscribe(
      // FUTURE - This is a lot of boilerplate. Can we reduce that?
      async (data: MultiChoiceItemChange) => {
        let selections = this.parseMultipleChoices(
          this.alignedGoal
        );
        
        if (data.isAdded) {
          selections.push(data.value);
        } else {
          selections = selections.filter((selection)=> selection !== data.value)
        }

        this.alignedGoal = selections.sort().join(';');
      }
    );
  }
}
