import { ICompanyUpdate, IDealUpdate, IFundingUpdate, UpdateStatus } from "../entity";
import { Company, Deal, Funding } from "../model";

export const TAG_SEPARATOR = '_#_';
export function getBooleanAsNumber(value: string): number {
    return isTruthy(value) ? 1 : 0;
}
export function isTruthy(value: string): boolean {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return value === '1' || (value as any) === 1 || (value as any) === true;
}

export function isBooleanProperty(propertyName: string): boolean {
    return (
        isFirstTimeEntrepreneurProperty(propertyName) ||
        propertyName === 'isConfidential' ||
        propertyName === 'isDeleted' ||
        propertyName === 'isJnjDeal'
    );
}

export function isFirstTimeEntrepreneurProperty(propertyName: string): boolean {
    return (
        propertyName === 'firstTimeEntrepreneur' ||
        propertyName === 'minorityLed' ||
        propertyName === 'womenLed'
    );
}

export function isDealOrFundingUpdateDeclined(
    update: IDealUpdate | IFundingUpdate
): boolean {
    // Deal & funding updates are approved or declined in their entirety,
    // so if one field is declined, the whole update is declined.
    return (
        update?.updateFields?.length &&
        update.updateFields[0].status === UpdateStatus.Declined
    );
}

export function buildDealOrFundingFromUpdate(
    obj: Deal | Funding,
    update: IDealUpdate | IFundingUpdate
): Deal | Funding {
    update.updateFields.map((updateField) => {
        obj[updateField.name] = isBooleanProperty(updateField.name)
            ? getBooleanAsNumber(updateField.newValue)
            : updateField.newValue;
    });
    return obj;
}

export function isCompanyContactTitle(propertyName: string): boolean {
    return propertyName === 'companyContactTitle';
}

export function applyUpdate(update: ICompanyUpdate, company: Company): void {
    if (!update) {
        return;
    }

    update.updateFields?.map((updateField) => {
        if (updateField.status === UpdateStatus.Declined) {
            return;
        }

        if (updateField.name === 'tags') {
            company[updateField.name] =
                updateField.newValue.split(TAG_SEPARATOR);
            return
        }

        if (isBooleanProperty(updateField.name)) {
            company[updateField.name] = getBooleanAsNumber(
                updateField.newValue
            );
            return;
        }

        if (isCompanyContactTitle(updateField.name)) {
            if (company.companyContact) {
                company.companyContact.title = updateField.newValue;
            }
            return;
        }

        company[updateField.name] = updateField.newValue;
    });

    company.deals = company.deals ?? [];
    update.dealUpdates?.filter((dealUpdate) => !isDealOrFundingUpdateDeclined(dealUpdate)).map((dealUpdate) => {
        const deal = company.deals.find((d) => d.dealId === dealUpdate.modelId);
        if (deal) {
            dealUpdate.updateFields.map((updateField) => {
                deal[updateField.name] = isBooleanProperty(updateField.name)
                    ? getBooleanAsNumber(updateField.newValue)
                    : updateField.newValue;
            });
        } else {
            const newDeal = buildDealOrFundingFromUpdate(
                new Deal(),
                dealUpdate
            ) as Deal;
            newDeal.dealId = dealUpdate.modelId;
            company.deals.push(newDeal);
        }
    });

    company.funding = company.funding ?? [];
    update.fundingUpdates?.filter((fundingId) => !isDealOrFundingUpdateDeclined(fundingId)).map((fundingUpdate) => {
        const funding = company.funding.find(
            (f) => f.fundingId === fundingUpdate.modelId
        );
        if (funding) {
            fundingUpdate.updateFields.map((updateField) => {
                funding[updateField.name] = isBooleanProperty(updateField.name)
                    ? getBooleanAsNumber(updateField.newValue)
                    : updateField.newValue;
            });
        } else {
            const newFunding = buildDealOrFundingFromUpdate(
                new Funding(),
                fundingUpdate
            ) as Funding;
            newFunding.fundingId = fundingUpdate.modelId;
            company.funding.push(newFunding);
        }
    });
}