import { Component, Input } from '@angular/core';
import { ComponentBase } from '../../../../_common/components/_component.base';
import { Company, PublicOverrides } from 'company-finder-common';

@Component({
  selector: 'public-information',
  templateUrl: './public-information.component.html',
  styleUrls: ['./public-information.component.scss'],
})
export class PublicInformationComponent extends ComponentBase {
  @Input()
  public company: Company;

  public get includeInPublicView(): PublicOverrides {
    return this.featureSwitches.includeInPublicView;
  }
}

