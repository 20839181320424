import { Component, Input } from '@angular/core';
import { ComponentBase } from '../../../../_common/components/_component.base';
import { Company } from 'company-finder-common';

@Component({
  selector: 'blue-knight-milestones',
  templateUrl: './blue-knight-milestones.component.html',
  styleUrls: ['./blue-knight-milestones.component.scss'],
})
export class BlueKnightMilestonesComponent extends ComponentBase {
  @Input()
  public company: Company;

  public properties: string[] = [
    'commercialPartnerships',
    'rAndDPartnerships',
    'otherPartnerships',
    'challenges',
    'priorities',
    'compositionOfMatter',
    'securedIP',
    'filedIP',
    'addtlIPDetails',
    'currencyBlueKnight',
    'fundingStage',
    'fundingStageDetails',
    'nonDilutiveFunding',
    'dilutiveFunding',
    'majorMilestones',
    'currentPharmaStage',
    'currentMedTechStage',
    'stageDetails',
    'regulatoryStatus',
    'anticipatedComercialProductYr',
    'nonLeadRegStatus',        
  ];
}

